import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { UtilService } from '../utils/util.service';
import { from, Observable, of } from 'rxjs';
import { IStakeholderAllocationDetails, IStakeholderSummary } from '../qubit-x/portfolio/fund-allocation/interface/stakeholder.allocation';
import { IStakeholderFund } from '../qubit-x/portfolio/fund-allocation/interface/stakeholder.util';
import { IStakeholderUser } from '../qubit-x/portfolio/fund-allocation/interface/stakeholder.user';
import { IStakeholderSelection } from '../qubit-x/portfolio/fund-allocation/interface/stakeholder.selection';
import { EnabledVersion, NewVersionList, VersionList, VersionListDetails, VlName } from '../mapping-managment-config/versions/version-interfaces';
import { AssignedCompany, CompaniesInFundMap, CompanyToVersionListAndAttributeList } from '../mapping-managment-config/company-assignment/ca-interfaces';
import { AttributeListDetails, AttributeListName, NewAttributesMap } from '../mapping-managment-config/attributes/attribute-interfaces';
import { AttributeList } from '../mapping-managment-config/attribute-list-container/attr-list-interfaces';
import { ITrancheStatus, ITrancheSecurity, ITrancheConclude } from '../qubit-x/portfolio/debt/tranching-analysis/interface';
import { DebtAssignedCompany } from '../mapping-managment-config/company-assignment/credit-company-assignment/debt-ca-interfaces';
import { S73Product } from '../utils/s73-product';
import { UserManagementService } from './user-management.service';

interface IUserIdAndOrganizationId {
  id: string;
  organization: {
    id: string;
  };
}

export interface ApiResponse<T> {
  success: boolean;
  message: string;
  errorCode: null | number | string;
  response: T;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  formData = {};

  formId = "";
  instaVal;
  formType;
  exchnageRate;
  sourceCurrency;
  apiVersion = "V1";

  fundId = ""

  constructor(private http:HttpClient, private router: Router, private utilService : UtilService, private ums: UserManagementService) { }

  getFormData(formId, version) {
    const url = environment.apiVersion + '/dd/form/' + formId + '/' + version + '/CORP';
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getformDetails(formId) {
    const url = environment.apiVersion + environment.apiSAFNewForm + "/details/" + formId;

    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});

    return this.appHttpRequest("GET", "saf", url, {});
  }

  getAllForms() {
    // const url = environment.apiVersion + environment.apiSAFNewForm + "?assesseeUserId=assessee_1";
    // return this.appHttpRequest("GET", "saf", url, null);
  }

  getAllValuationDateById(investmentDateId) {
    const url = environment.apiVersion + "/saf/forms?formId=" + investmentDateId;
    return this.appHttpRequest("GET", "saf", url, {});
  }

  getNewForm() {
    const url = environment.apiVersion + environment.apiSAFNewForm;
    return this.appHttpRequest("POST", "saf", url, {});
  }

  submitForm(reqBody) {
    const url = "/v1/saf/equity-valuation/submit";
    return this.appHttpRequest("POST", "saf", url, reqBody);
  }

  setExitStatus(requestBody) {
    const url = environment.apiVersion+"/saf/form/exit_status";
    // return this.http.post( "http://localhost:8080/api" + url, requestBody, { observe: 'response' }).toPromise();

    return this.appHttpRequest("POST", "saf", url, requestBody);
  }

  getFormId() {
    return this.formId;
  }

  setFormId(formId) {
    this.formId = formId;
  }

  setFormData(formData) {
    this.formData = formData;
  }

  getFundByFundId(fundId) {
    const url = environment.apiVersion + "/funds/get/" + fundId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  assignUsersToFund(body) {
    const url = environment.apiVersion + "/funds/assign";
    return this.appHttpRequest("POST", "vcp", url, body);

    // return this.http.post("http://localhost:8081/api" + url, body, {observe: "response"});
  }

  getInvestMentSummaryCombinedData(body){

    console.log("investment summary combned data");

    return this.http.post("http://localhost:8081/api/v1/investment-summary/getinvestmentsummarydata", body, {observe: "response"});

  }

  processSftpFiles(){

    console.log("processing of the sftp files started");

    const url = environment.apiVersion + "/processSftpFiles";

    // const url1 = "localhost:8805/api/v1/processSftpFiles";

    return this.appHttpRequest("GET","thirdParty",url,null);

    // return this.http.get(url1,{observe:"response"});

  }

  reProcessSftpFiles(fileType: String){

    console.log("Re processing of the sftp files started");
    const url = environment.apiVersion + "/sftp/reprocess-sftp-files/" + fileType;
    // const url1 = "localhost:8805/api/v1/sftp/reprocess-sftp-files/";
    return this.appHttpRequest("GET","thirdParty",url,null);
    // return this.http.get(url1,{observe:"response"});

  }

  addSftpCompanyDetails(body){

    const url1 = "localhost:8805/api/v1/sftp/company/addCompany";

    const url = environment.apiVersion + "/sftp/company/addCompany";

    return this.appHttpRequest("POST", "thirdParty", url, body);



    // return this.http.post(url1, body, {observe: "response"});

  }

  updateSftpCompanyDetails(body){

    const url = environment.apiVersion + "/sftp/company/updateCompany";

    const url1 = "localhost:8805/api/v1/sftp/company/updateCompany";

    return this.appHttpRequest("PUT", "thirdParty", url, body);

    // return this.http.post(url1, body, {observe: "response"});

  }

  deleteSftpCompanyDetails(companyId){

    const url1 = "localhost:8805/api/v1/sftp/company/deleteCompany" + companyId;

    const url = environment.apiVersion + "/sftp/company/deleteCompany/" + companyId;

    return this.appHttpRequest("DELETE", "thirdParty", url,null);
    // return this.http.get(url1, {observe: "response"});
  }

  getAllAvailableFilesInInput(){
    const url1 = "localhost:8805/api/v1/sftp/getAllCompanies";
    const url = environment.apiVersion + "/sftp/getAllInputFiles";
    return this.appHttpRequest("GET", "thirdParty", url,null);
    // return this.http.get(url1,{observe : "response"})
  }

  getAllSftpCompanies(){
    const url1 = "localhost:8805/api/v1/sftp/company/getAllCompanies"
    const url = environment.apiVersion + "/sftp/company/getAllCompanies";
    return this.appHttpRequest("GET", "thirdParty", url,null);
    // return this.http.get(url1,{observe : "response"})

  }

  uploadSftpFile(file: File,isPME: boolean){
    const data: FormData = new FormData();
    data.append('file',file);
    const url = environment.apiVersion + "/sftp/saveFileToSftp?isPME="+isPME;
    return this.appHttpRequest("POST", "thirdParty", url, data);
  }

  assignUsersToFundForms(body) {
    const url = environment.apiVersion + "/forms/assignUsersToFund";
    return this.appHttpRequest("POST", "saf", url, body);

    // return this.http.post("http://localhost:8080/api" + url, body, {observe: "response"});
  }

  getMatchingCompaniesForSearch(userId, expression) {
    const url = environment.apiVersion + "/saf/company/"+ userId + "/" + expression;
    return this.appHttpRequest('GET', 'saf', url, {});
  }

  getFundList(orgId, userId){
    const url = environment.apiVersion + "/approval-matrix/all-funds?orgId=" + orgId + "&userId=" + userId;
    return this.appHttpRequest('GET', 'vcp', url, {});
  }

  getCustomAttributes(companies){
    const url = environment.apiVersion + "/investment-summary/get-all-attributes-by-companies";
    return this.appHttpRequest('POST', 'vcp', url, {companies});
  }

  getFundDetails(fundId) {
    const url = environment.apiVersion + "/funds/get/" + fundId;
    return this.appHttpRequest("GET", "vcp", url, null);

    // return this.http.get("http://localhost:8081/api" + url, {observe: "response"});
  }

  getLatestValuationDatesByCompanyId(body){
    const url = environment.apiVersion + "/saf/latest-valuation-dates-by-company";
    return this.appHttpRequest('POST', 'saf', url, body);
    // return this.http.post("http://localhost:8080/api" + url, body, { observe: "response" })
  }

  getVersionsInValuationDates(companyId, date){
    const url = environment.apiVersion + "/saf/all-versions/" + companyId + "/" + date;
    return this.appHttpRequest('GET', 'saf', url, null);
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response" })
  }

  getCompaniesForDebt(body){
    const url = environment.apiVersion + '/search/get_shared_companies';
    return this.appHttpRequest("POST", "debt", url, body);
  }

  getSecuritiesForDebtCompanies(body){
    const url = environment.apiVersion + '/search/get_securities_by_company';
    return this.appHttpRequest("POST", "debt", url, body);
  }

  getValuationDatesBySecurities(body){
    const url = environment.apiVersion + '/search/get_valuation_dates_by_securities';
    return this.appHttpRequest("POST", "debt", url, body);
  }

  getUniqueAttributesList(companyId){
    const url = environment.apiVersion + '/investment-summary/attributes/unique-list/company/' + companyId;
    return this.appHttpRequest('GET', 'vcp', url, null);
    // return this.http.get("http://localhost:8081/api" + url, { observe: "response" })
  }

  getAttributesListByCompany(companyId){
    const url = environment.apiVersion + '/investment-summary/attributes/company/' + companyId;
    return this.appHttpRequest('GET', 'vcp', url, null);
  }


  getDataForExternalValuer(formId){
    const url = "/v1/dd/externalValuer/" + formId;
    // return this.http.get("http://localhost:8081/api" + url,{observe: "response"});
    return this.appHttpRequest('GET', 'vcp', url, null);
  }


  getAllCompaniesByUser(userId) {
    const url = environment.apiVersion + "/saf/get_shared_companies/" + userId;
    return this.appHttpRequest('GET', 'saf', url, null);
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response" })
  }

  getCompanyListFromFunds(assessorUserId, approvalStatus,fundId){
    const url = environment.apiVersion + "/saf/approval-matrix/form?assessorUserId=" + assessorUserId + "&fundId=" + fundId +"&approvalStatus=" + approvalStatus;
    return this.appHttpRequest('GET', 'saf', url, {});
  }

  getCompanyListForMonitoring(payload) {
    const url = environment.apiVersion + "/saf/all_companies_in_funds";
    return this.appHttpRequest('POST', 'saf', url, payload);
  }


  getImageUrl(fileData, fileName) {

    const url =  "/v1/direct-file-storage/file/" + fileName;
    const formData = new FormData();
    formData.append('file', fileData);

    // return this.http.post("http://localhost:8081/api" + url, formData, { observe: 'response' }).toPromise();

    return this.appHttpRequest("POST", "vcp", url, formData).toPromise();
  }

  saveLogoUrl(requestBody) {
    const url = environment.apiVersion+"/saf/form/save_logo";

    // return this.http.post( "http://localhost:8080/api" + url, requestBody, { observe: 'response' }).toPromise();

    return this.appHttpRequest("POST", "saf", url, requestBody).toPromise();
  }

  saveDebtLogoUrl(requestBody) {
    const url = environment.apiVersion+"/debt/form/save_logo";

    // return this.http.post( "http://localhost:8080/api" + url, requestBody, { observe: 'response' }).toPromise();

    return this.appHttpRequest("POST", "saf", url, requestBody).toPromise();
  }

  getFileFromDirectStorage(fileName) {
    // const url = "/api/v1/direct-file-storage/file/" + fileName;
    const url = "/api/v1/files/download-from-blob?type=FILE_ID&id=" + fileName;
    const gateway = "http://localhost:8081";

    // const gateway = environment.apiGateway;

    const h = this.getHeaderToken();

    return this.http.get(gateway + url, {
      responseType: 'blob',
      headers: h,
      observe: 'response'
    });
  }

  assignUsersToSAFForm(body) {
    const url = environment.apiVersion + "/forms/assignUsersToForm";
    return this.appHttpRequest("POST", "saf", url, body);

    // return this.http.post("http://localhost:8080/api" + url, body, {observe: "response"});
  }

  assignUsersToForm(body) {
    const url = environment.apiVersion + "/forms/assignUsersToForm";
    return this.appHttpRequest("POST", "vcp", url, body);

    // return this.http.post("http://localhost:8081/api" + url, body, {observe: "response"});
  }

  getAssignedSAFForms(userId) {
    const url = environment.apiVersion + "/forms/getAssignedForms/" + userId;
    return this.appHttpRequest("GET", "saf", url, null);
  }

  getAssignedForms(userId) {
    const url = environment.apiVersion + "/forms/getAssignedForms/" + userId;
    return this.appHttpRequest("GET", "vcp", url, null);
    // const url = "http://localhost:8081/api/v1/forms/getAssignedForms/" + userId;
    // return this.http.get(url, {observe: "response"});
  }

  getBUSummaryDataAndExchangeRates(body) {
    const url = environment.apiVersion + "/saf/bu-consol/get-consol-summary";
    return this.appHttpRequest("POST", "saf", url, body);
    // const url = "http://localhost:8080/api/v1/saf/bu-consol/get-consol-summary";
    // return this.http.post(url, body, {observe: "response"});
  }

  getBusinessUnitsByConsolFormWithInvestmentSummary(consolFormId) {
    const url = environment.apiVersion + "/saf/business-unit/" + consolFormId;
    return this.appHttpRequest("GET", "saf", url, null);
    // const url = "http://localhost:8080/api/v1/saf/bu-consol/get-consol-summary";
    // return this.http.post(url, body, {observe: "response"});
  }

  shareFormToExternalUser(formId, input) {
    const url = environment.apiVersion + "/forms/share/" + formId;
    return this.appHttpRequest("POST", "saf", url, input);
    //return this.http.post("http://localhost:8080/api" + url, input, {observe: "response"});
  }

  getSLSummaryData(consolFormId) {
    const url = environment.apiVersion + '/cca/waterfall/sl-summary?formId=' + consolFormId;
    return this.appHttpRequest("GET", "valuation", url, null);

    // return this.http.get("http://localhost:8807/api" + url, { observe: 'response' });
  }

  initBUSummaryAnalysis(consolFormId, reqBody) {
    const url = environment.apiVersion + '/business-unit/analysis?consolFormId=' + consolFormId;
    return this.appHttpRequest("POST", "valuation", url, reqBody);

    // return this.http.post("http://localhost:8807/api" + url, reqBody, { observe: 'response' });
  }

  getSharedFormsToExternalUser(userId) {
    const url = environment.apiVersion + "/forms/get-shared-forms-to-external-user/" + userId;
    return this.appHttpRequest("GET", "saf", url, null);

    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  getIVSavedForms(userId) {
    const url = environment.apiVersion + "/forms/savedForms/" + userId;
    return this.appHttpRequest("GET", "vcp", url, null);
    // const url = "http://localhost:8081/api/v1/forms/savedForms/" + userId;;
    // return this.http.get(url, {observe: "response"});
  }

  saveFormStatus(formId, body) {
    const url = environment.apiVersion + "/forms/form/status/" + formId
    return this.appHttpRequest("POST", "vcp", url, body);
    // const url = "http://localhost:8081/api/v1/forms/form/status/" + formId
    // return this.http.post(url, body, {observe: "response"});
  }

  getSimilarCountries(value) {
    // const url = "http://localhost:8807/api/v1/typeAhead/country/" + value;;
    // return this.http.get(url, {observe: "response"});
    const url = environment.apiVersion + '/typeAhead/country/' + value;
    return this.appHttpRequest('GET', 'valuation', url, {});
  }

  getTwitterData(tagsList: any) {
    return this.http
      .post(
        environment.twitterService,
        JSON.stringify({
          tags_list: tagsList,
          start_date: moment()
            .subtract(30, 'day')
            .format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD')
        }),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .toPromise();
  }

  searchNotFoundWebiste(website) {
    const url = environment.apiVersion + '/enrichcompany?domain=' + website;
    //https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/enrichcompany?domain={domain}

    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  requestNewCompany(duplicate, body) {
    // https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/detailedenrichcompany
    const url = environment.apiVersion + '/detailedenrichcompany';

    return this.appHttpRequest('POST', 'thirdParty', url, body);
  }

  searchCompanyAutoComplete(searchName, type, apiVersion) {
    let version = "";
    if (apiVersion == "V1") {
      version = environment.apiVersion;
    }
    else {
      version = "/v2";
    }
    if(type === "Name") {
    // /thirdpartyapi/api/v1/autosuggest-companyname/bla bla car
      const url = version + '/autosuggest-companyname/' + searchName;
      return this.appHttpRequest('GET', 'thirdParty', url, null);

    } else {
      // https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/autosuggest-domainname?domain={domain}
      const url = version + '/autosuggest-domainname?domain=' + searchName;
      return this.appHttpRequest('GET', 'thirdParty', url, null);
    }
  }

  getSpecialitiesAutoSuggestion(sp) {
    const url = environment.apiVersion + "/datascreener/" + sp + "?resultSize=10"

    return this.appHttpRequest('GET', 'valuation', url, {});

    // const url = "http://localhost:8807/api/v1" + "/datascreener/" + sp + "?resultSize=10";
    // return this.http.get(url, {observe: "response"});
  }

  getSimilarSpecialities(sp) {
    // https://vcpdevapims.azure-api.net/valuation/api/v1/datascreener/similarspecialities/{specialty}?resultSize={resultSize}
    const url = environment.apiVersion + "/datascreener/similarspecialities/" + sp + "?resultSize=10"

    return this.appHttpRequest('GET', 'valuation', url, {});
  }

  getScreenedCompanies(body) {
    const url = environment.apiVersion + "/datascreener/companies"
    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  getScreenedCompaniesViz(body) {
    const url = environment.apiVersion + "/datascreener/companies/visualize"
    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  searchCompaniesWithFields(companyName, fields) {
    // const url = "http://localhost:8805/api/v1/get-companies-fields/" + companyName;
    // return this.http.post(url, fields, { observe: "response"})
    const url = environment.apiVersion + '/get-companies-fields/' + companyName;

    return this.appHttpRequest('POST', 'thirdParty', url, fields);
  }

  searchCompany(searchName) {
    // https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/elasticsearch/search-company/{company}
    const url = environment.apiVersion + '/elasticsearch/search-company/' + searchName;

    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  searchSavedCompany(userId, searchName) {
    // const url = "http://localhost:8081/api/v1/forms/matchingSavedForms/" + userId + "/" + searchName;
    // return this.http.get(url, {observe: "response"});
    const url = environment.apiVersion + "/forms/matchingSavedForms/" + userId + "/" + searchName;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getCompanyConditions(reqBody){
    const url = environment.apiVersion + '/get_company_conditions';
    return this.appHttpRequest("POST", "approval", url, reqBody);

    // return this.http.post("http://localhost:8807/api" + url, reqBody, { observe: 'response' });
  }

  updateApprovalObject(reqBody){
    const url = environment.apiVersion + '/update_approval_object'
    return this.appHttpRequest('PUT', 'approval', url, reqBody);
  }

  getDocumentRecalledStatus(approvalObjectId){
    const url = environment.apiVersion + '/getstatus?existingApprovalObjectId=' + approvalObjectId
    return this.appHttpRequest('POST', 'approval', url, null);
  }

  cloneValuationDateVersion(reqBody){
    const url = environment.apiVersion + "/funds/clone-valuation-date-versions"
    return this.appHttpRequest("POST", "vcp", url, reqBody);

    // const url = "http://localhost:8081/api/v1/funds/clone-valuation-date-versions"
    // return this.http.post(url, reqBody, {observe: "response"});
  }

  getHeaderToken() {
    const authCode = this.ums.getHeaderToken();
    const authSelectedCode = this.ums.getSelectedUserAuthToken();

    const h = new HttpHeaders({"X-AUTH-TOKEN": authCode, "SELECTED-X-AUTH-TOKEN": authSelectedCode});

    return h;
  }

  getUserId(): string {
    const user = localStorage.getItem("73S-User");

    if (user && user.length > 0) {
      try {
        const userObj: IUserIdAndOrganizationId = JSON.parse(user);
        return userObj.id;
      } catch (e) {
        console.log("Error parsing user object", e);
      }
    }

    return "";
  }

  createNewForm(companyName, assesseeUserId, type, loggedInUserDetails, questions, industry?) {
    const assessorUserId = loggedInUserDetails.id;

    const url = environment.apiVersion + environment.apiSAFNewForm;
    return this.appHttpRequest("POST", "saf", url, {
      "assessorUserId" : assessorUserId,
      "assesseeUserId" : assesseeUserId,
      "formType" : type,
      "details" : {
        "companyName" : companyName,
        "vcOrgName": loggedInUserDetails.organization.orgName,
        "vcName": loggedInUserDetails.userName,
        "questions": questions,
        "status": type === "T2"? "Submitted" : "Form Initiated",
        "sector": industry
      }
    });
  }

  createNewCompanyWithVersion(input, selectedUserDetails, version, versionName, frozen, loggedInUser, fundCompany?) {
    const assessorUserId = selectedUserDetails.id;
    const body = {
      "createdById": loggedInUser.id,
      "fundCompany": fundCompany,
      "assessorUserId" : assessorUserId,
      "assesseeUserId" : selectedUserDetails.userId,
      "valuationDate": input.valuationDate,
      "formType" : "corp",
      "groupFormId": input.groupFormId,
      "security": input.security,
      "companyName" : input.name,

      "version": version,
      "frozen": frozen,
      "businessUnitsNumber": input.businessUnitsNumber,
      "issuerCompanyId":input.issuerCompanyId,

      "details" : {
        // "companyName" : input.name,
        "createdByName": loggedInUser.userName,
        "vcOrgName": selectedUserDetails.organization.orgName,
        "vcName": selectedUserDetails.userName,
        "status": "Initiated",
        "businessUnitsNumber": input.businessUnitsNumber,

        "versionName": versionName,
        "frozen": frozen,
        company_id: input.company_id,
        valuationType: input.valuationType,
        rollOverFromId: input.replicatedValDateId,
        updateMarketData: input.updateMarketData,
        marketDataDate: input.marketDataDate,
        refreshInvesteefinancials: input.refreshInvesteefinancials,
        cashFlowDate: input.financialRefreshDate
      }
    }
    if(!body.issuerCompanyId){
      delete body.issuerCompanyId;
    }

    if(input.oldConsolForm && input.businessUnitsNumber > 0) {
      body["oldConsolForm"] = input.oldConsolForm;
    }

    body["rollOverFrom"] = input.oldConsolForm;

    if(versionName) {
      body["versionName"] = versionName;
    }

    const url = environment.apiVersion + environment.apiSAFNewForm;

    // return this.http.post("http://localhost:8080/api" + url, body, {observe: "response"});

    return this.appHttpRequest("POST", "saf", url, body);
  }

  equityFormSubmission(previousValuationDateId, valuationDate, versionName, frozen, orgId?, userId?) {
    let url = environment.apiVersion + '/valuation/debt-model/autoRollover?previousValuationDateId=' + previousValuationDateId
      + '&valuationDate=' + valuationDate + '&submitForm=false&versionName=' + versionName + '&frozen=' + frozen;

    if(orgId) { url += "&orgId=" + orgId }

    if(userId) { url += "&userId=" + userId }

    return this.appHttpRequest("GET", "debt", url, null);
    //return this.http.get("http://localhost:8808/api" + url, { observe: "response" });
  }

  equityRollover(previousFormId, valuationDate, prevValuationDate, versionName, frozen, orgId?, userId?, groupFormId?) {
    let url = environment.apiVersion + '/valuation/debt-model/equity-waterfall-rollover?previousFormId=' + previousFormId + '&valuationDate=' + valuationDate +
      '&prevValuationDate=' + prevValuationDate + '&versionName=' + versionName + '&frozen=' + frozen;

    if(orgId) { url += "&orgId=" + orgId }

    if(userId) { url += "&userId=" + userId }

    if(groupFormId) { url += "&groupFormId=" + groupFormId }

    return this.appHttpRequest("GET", "debt", url, null);
    //return this.http.get("http://localhost:8808/api" + url, { observe: "response" });
  }

  createNewCompany(input, loggedInUserDetails, version, fundCompany?) {
    const assessorUserId = loggedInUserDetails.id;
    const body = {
      "fundCompany": fundCompany,
      "assessorUserId" : assessorUserId,
      "assesseeUserId" : loggedInUserDetails.userId,
      "valuationDate": input.valuationDate,
      "formType" : "corp",
      "groupFormId": input.groupFormId,
      "security": input.security,

      "version": version,

      "details" : {
        "companyName" : input.name,
        "vcOrgName": loggedInUserDetails.organization.orgName,
        "vcName": loggedInUserDetails.userName,
        "status": "Initiated"
      }
    }

    if(version === 3) {
      body.details["businessUnit"] = input.businessUnit;
    }

    // return this.http.post("http://localhost:8080/api/v1/" + environment.apiSAFNewForm, body, {observe: "response"});

    const url = environment.apiVersion + environment.apiSAFNewForm;
    return this.appHttpRequest("POST", "saf", url, body);
  }

  createNewCorporateCompanies(input, companies, loggedInUserDetails, version, corporateCompany?) {
    const assessorUserId = loggedInUserDetails.id;
    const body = {
      "fundCompany": corporateCompany,
      "assessorUserId" : assessorUserId,
      "assesseeUserId" : loggedInUserDetails.userId,
      "valuationDate": input.valuationDate,
      "formType" : "corp",
      "groupFormId": input.groupFormId,
      "security": input.security,

      "version": version,

      "details" : {
        "vcOrgName": loggedInUserDetails.organization.orgName,
        "vcName": loggedInUserDetails.userName,
        "status": "Initiated"
      },

      "companies": companies
    }


    const url = environment.apiVersion + "/saf/corporate/form";
    //ToDo:CORP Replace with actual API end point
    // return this.http.post("http://localhost:8080/api/v1" + url, body, {observe: "response"});

    return this.appHttpRequest("POST", "saf", url, body);

    //ToDo:CORP Remove this when api is ready
    // return from(new Promise<{body: {response: any}}>((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve({body: { response: "Company created"}});
    //     // reject({message: 'Error occured'});
    //   }, 1000);
    // }));
  }

  getDraft(formId) {
    const url = environment.apiVersion + "/saf/answer/draft/" + formId;
    return this.appHttpRequest("GET", "saf", url, null);
  }

  copyDraftAnswers(formId, answers) {
    const url = environment.apiVersion + "/saf/answer/draft/" + formId;
    return this.appHttpRequest("PUT", "saf", url, answers)
  }

  copyFormAnswers(srcFormId, destFormId) {
    const url = environment.apiVersion + "/saf/answer/copyDraft/" + srcFormId + "/" + destFormId;
    return this.appHttpRequest("POST", "saf", url, null);

    // return this.http.post("http://localhost:8080/api" + url, null, {observe: "response"});
  }

  createT2Form(newFormId, loggedInUserDetails, formData) {
    const url = environment.apiVersion + "/dd/form/t2"

    return this.appHttpRequest("POST", "vcp", url, {
      "formId": newFormId,
	    "vcId": loggedInUserDetails.id,
      "formData": JSON.stringify(formData)
    });
  }

  getYellowReportSummary(apiReq) {
    const url = environment.apiVersion + "/yellow-report/get-portfolio-summary-by-funds"

    return this.appHttpRequest("POST", "vcp", url, apiReq);

    // return this.http.post("http://localhost:8081/api" + url, apiReq, {observe: "response"})
  }

  getYellowReportSummaryV2(apiReq) {
    const url = environment.apiVersion + "/yellow-report/get-portfolio-summary-by-funds-v2"

    return this.appHttpRequest("POST", "vcp", url, apiReq);

    // return this.http.post("http://localhost:8081/api" + url, apiReq, {observe: "response"})
  }

  getValueBridgeForYellowReport(reqBody, fundId) {
    const url = environment.apiVersion + "/yellow-report/get-value-bridge/" + fundId

    return this.appHttpRequest("POST", "vcp", url, reqBody);

    // return this.http.post("http://localhost:8081/api" + url, reqBody, {observe: "response"})
  }

  updateSummaryAndValuationDateAttributes(reqBody){
    const url = environment.apiVersion + '/portfolio-summary/save-val-date-and-summary-attr-by-company-id';
    return this.appHttpRequest('POST', 'vcp', url, reqBody);

    // return this.http.post("http://localhost:8081/api" + url, reqBody, {observe : "response"});
  }

  updateSummaryAttributesOnTransactionsChange(reqBody){
    const url = environment.apiVersion + '/portfolio-summary/save-val-date-summary-metrics-by-fund-id';
    return this.appHttpRequest('POST', 'vcp', url, reqBody);

    // return this.http.post("http://localhost:8081/api" + url, reqBody, {observe : "response"});
  }

  getPortfolioSummary(apiReq) {
    const url = environment.apiVersion + "/portfolio-summary/get-portfolio-summary"

    return this.appHttpRequest("POST", "vcp", url, apiReq);

    // return this.http.post("http://localhost:8081/api" + url, apiReq, {observe: "response"})
  }

  addEventNotification(type, eventDetails, event, eventId, orgId) {
    const url = environment.apiVersion + "/dd/addEventNotification"

    return this.appHttpRequest("POST", "vcp", url, {
     "eventType": type,
     "eventDetails": eventDetails,
     "event": event,
     "eventId": eventId,
     "orgId": orgId
    });
  }

  addFormCreationNotification(type, newFormId, orgId) {
    this.addEventNotification("FORM-"+ type, null, "CREATED", newFormId, orgId).subscribe(notification=>{
      // console.log("FORM-"+ type + " form notification is added");
    }, error=>{
      console.log("FORM-"+ type + "Event notification save failed", error);
    });

    this.addEventNotification("FORM-"+ type, null, "CREATED", newFormId, "28c5d625-025d-46af-a1ce-7f3e7eb9cfb0").subscribe(notification=>{
      // console.log("73S FORM-"+ type + " form notification is added");
    }, error=>{
      console.log("73S FORM-"+ type + "Event notification save failed", error);
    })
  }

  getNotificationsV2(userId, orgId) {
    const url = environment.apiVersion + "/event_notification_v2/get_all_by_orgid_in_desc?userId=" + userId

    return this.appHttpRequest("GET", "vcp", url, null);

    // return this.http.get("http://localhost:8081/api" + url, { observe: "response"});

    /*
    return of({ body: {
        "response" : [ {
          "id" : "7b185366-a674-4043-9ce1-678bffe6183e",
          "createdBy" : "e6025da8-b869-498d-bea1-8712ff5dd929",
          "createdDate" : 1646017034748,
          "lastModifiedBy" : "STATUS_UPDATE",
          "lastModifiedDate" : 1646015374552,
          "orgId" : "b2ccc9fd-fe19-41e3-b49e-a65b4113d8b3",
          "eventType" : "FORM_SUBMISSION",
          "eventObjectId" : "48229778-d33c-49ab-bbcd-012f3d72da20",
          "eventObjectParentId" : "86803819-96f8-4524-98e9-7925bc646752",
          "eventStatus" : "IN_PROGRESS",
          "readStatus" : false,
          "label" : "{\"fund\":\"Fund Level VB\",\"companyName\":\"CD Projekt Red\",\"valuationNode\":\"2022-04-12\",\"version\":\"FIRST\"}",
          "new" : false
        }, {
          "id" : "98a9b33b-f959-4ba8-ae67-4adb36534940",
          "createdBy" : "e6025da8-b869-498d-bea1-8712ff5dd929",
          "createdDate" : 1645856802000,
          "lastModifiedBy" : "STATUS_UPDATE",
          "lastModifiedDate" : 1645856860000,
          "orgId" : "b2ccc9fd-fe19-41e3-b49e-a65b4113d8b3",
          "eventType" : "FORM_SUBMISSION",
          "eventObjectId" : "7dd471e3-418e-47a6-857c-a1e334849f65",
          "eventObjectParentId" : "86803819-96f8-4524-98e9-7925bc646752",
          "eventStatus" : "COMPLETED",
          "readStatus" : false,
          "label" : "{\"fund\":\"Fund Level VB\",\"companyName\":\"CD Projekt Red\",\"valuationNode\":\"2022-02-15\",\"version\":\"FIRST\"}",
          "new" : false
        }, {
          "id" : "0bf87de4-540c-439d-a4cd-26b7020b6586",
          "createdBy" : "e6025da8-b869-498d-bea1-8712ff5dd929",
          "createdDate" : 1645855178000,
          "lastModifiedBy" : "STATUS_UPDATE",
          "lastModifiedDate" : 1645855246000,
          "orgId" : "b2ccc9fd-fe19-41e3-b49e-a65b4113d8b3",
          "eventType" : "FORM_SUBMISSION",
          "eventObjectId" : "e712b8da-bcc0-4c84-ab8c-3a04857ed883",
          "eventObjectParentId" : "86803819-96f8-4524-98e9-7925bc646752",
          "eventStatus" : "COMPLETED",
          "readStatus" : false,
          "label" : "{\"fund\":\"Fund Level VB\",\"companyName\":\"CD Projekt Red\",\"valuationNode\":\"2023-02-15\",\"version\":\"FIRST\"}",
          "new" : false
        }, {
          "id" : "dba2f9dc-5ff6-4fce-baa2-10056acffeb5",
          "createdBy" : "e6025da8-b869-498d-bea1-8712ff5dd929",
          "createdDate" : 1645854392000,
          "lastModifiedBy" : "STATUS_UPDATE",
          "lastModifiedDate" : 1645854450000,
          "orgId" : "b2ccc9fd-fe19-41e3-b49e-a65b4113d8b3",
          "eventType" : "FORM_SUBMISSION",
          "eventObjectId" : "00697669-e920-4e9e-b7a7-6e7c221f0e04",
          "eventObjectParentId" : "86803819-96f8-4524-98e9-7925bc646752",
          "eventStatus" : "COMPLETED",
          "readStatus" : false,
          "label" : "{\"fund\":\"Fund Level VB\",\"companyName\":\"CD Projekt Red\",\"valuationNode\":\"2022-02-15\",\"version\":\"FIRST\"}",
          "new" : false
        }
      ]}
    });
    */
  }

  updateReadStatusOfNotifications(notifications) {
    const url = environment.apiVersion + "/event_notification_v2/update_read_status_by_event_obj_id";
    return this.appHttpRequest("POST", "vcp", url, notifications);

    // return this.http.post("http://localhost:8081/api" + url, notifications, { observe: "response"});
  }

  getNotifications(loggedInUserDetails) {
    const url = environment.apiVersion + "/dd/getEventNotification?orgId=" + loggedInUserDetails.organization.id

    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getCustomAttributeWidgetData(valuationDateId){
    const url = environment.apiVersion + '/investment-summary/attributes/' + valuationDateId ;
    return this.appHttpRequest("GET", "vcp", url, valuationDateId);
    // return this.http.get("http://localhost:8081/api" + url, valuationDateId, {observe: "response"});
  }

  saveCustomAttribute(body){
    const url = "/v1/investment-summary/attributes";
    return this.appHttpRequest("POST", "vcp", url, body);

    // return this.http.post("http://localhost:8081/api" + url, body, {observe: "response"});
  }



  getRelatedCompanies(countries) {
    // /landing-page/companies
    const url = environment.apiVersion + '/landing-page/companies';
    // return this.http.post(url, countries, {headers: this.getHeaderToken(), observe: "response"})
    return this.appHttpRequest("POST", "thirdParty", url, countries);
  }

  getIRR(body) {
    const url = environment.apiVersion + "/irr/get";
    return this.appHttpRequest('POST', 'valuation', url, body);

    // let url = "http://localhost:8807/api/v1/irr/get";
    // return this.http.post(url, body, {observe: "response"});
  }

  getIRRList(reqbody){
    const url = environment.apiVersion + "/irr/get-all-irr";
    return this.appHttpRequest('POST', 'valuation', url, reqbody);

    // let url = "http://localhost:8807/api/v1/irr/get-all-irr";
    // return this.http.post(url, reqbody, {observe: "response"});
  }

  getIRRForMultipleRequests(reqbody){
    const url = environment.apiVersion + "/irr/get-irr-with-multiple-requests";
    return this.appHttpRequest('POST', 'valuation', url, reqbody);

    // let url = "http://localhost:8807/api/v1/irr/get-irr-with-multiple-requests";
    // return this.http.post(url, reqbody, {observe: "response"});
  }

  getIRRWithConsolidateReqBody(body) {
    // const url = environment.apiVersion + '/aiapi/generic-ai-api?API_KEY=XIRR';
    // return this.appHttpRequest('POST', 'thirdParty', url, body);

    const url = environment.apiVersion + '/xirr-evaluator/bulk-request';
    // return this.http.post("http://localhost:8807/api" + url, body, {observe: "response"})
    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  getPrecentageChangeinMoicForPortfolioCurrency(body, fundId, currency){
    const url = environment.apiVersion + '/portfolio-summary/get-transactions-in-currency?fundId='+fundId+'&currency='+currency
    return this.appHttpRequest('POST', 'vcp', url, body);

  }

  getFullyDilutedStakeForPortfolioSummaryTable(reqbody){
    const url = environment.apiVersion + '/waterfall/versions/getCapTableStakesFromValuationDateIds';
    return this.appHttpRequest('POST', 'vcp', url, reqbody);

  }

  getTagsForText(body) {
    const url = environment.apiVersion + "/crunchBase/getTagsFromAi";
    return this.appHttpRequest('POST', 'thirdParty', url, body);
  }

  getAllVCForms(userId, fundId, orgId?) {
    let url = environment.apiVersion + environment.apiSAFNewForm + "?assessorUserId="+ userId;

    if(fundId) { url += "&fundId=" + fundId }

    if(orgId) { url += "&orgId=" + orgId }

    return this.appHttpRequest("GET", "saf", url, null);

    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"})
  }

  getAllFormsForAllFunds(userId, funds) {
    let url = environment.apiVersion + environment.apiSAFNewForm + "/allFunds?assessorUserId="+ userId;
    return this.appHttpRequest("POST", "saf", url, funds);
    // return this.http.post("http://localhost:8080/api" + url, funds, {observe: "response"});
  }

  getUserEntityAccess(userId, entityId) {
    const url = "/v1/users/user-id/" + userId + "/entity-id/" + entityId;
    return this.appHttpRequest("GET", "uam", url, null);
  }

  getCompanyWithPermission(companyId, userId) {
    const url = environment.apiVersion + "/saf/get-form-details-with-permission?formId=" + companyId + "&userId=" + userId + "&draftNeeded=false";
    return this.appHttpRequest("GET", "saf", url, null);
  }

  getAllFormsVersions(groupFormId, valuationDate) {
    let url = environment.apiVersion + "/saf/form/versions?groupFormId="+ groupFormId + "&valuationDate="+valuationDate;
    return this.appHttpRequest("GET", "saf", url, null);
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  getGeographyData(body){
    //let url = environment.apiVersion + "/valuation/debt-model/getGeographyData";
    const url = environment.apiVersion + "/integration-apis/getGeographyData";
    return this.appHttpRequest("POST", "debt", url, body);
    // let url = "http://localhost:8808/api/v1/valuation/debt-model/getGeographyData";
    // return this.http.post(url, body, {observe: "response"});
  }

  getSectorData(body){
    //let url = environment.apiVersion + "/valuation/debt-model/getSectorData";
    const url = environment.apiVersion + "/integration-apis/getSectorData";

    return this.appHttpRequest("POST", "debt", url, body);
    // let url = "http://localhost:8808/api/v1/valuation/debt-model/getSectorData";
    // return this.http.post(url, body, {observe: "response"});
  }

  getGrossIRR(payload){
    // console.log("getgrossirr:", JSON.stringify(payload))
    const url = environment.apiVersion + "/valuation/debt-model/getGrossIRR";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();
  }

  getPercentOfPar(payload){
    const url = environment.apiVersion + "/valuation/debt-model/getPortfolioPercentageOfPar";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();
  }

  getDebtPortfolioSummaryTotals(payload){
    // const url = environment.apiVersion + "/valuation/debt-model/getPortfolioTotals";
    const url = environment.apiVersion + "/integration-apis/getPortfolioTotals";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();
    //return this.http.post("http://localhost:8808/api" + url, payload, { observe: "response"}).toPromise();
  }

  getAllFormsVersionsForAllValDates(groupFormId, allValDates) {
    let payload = {
      groupFormId: groupFormId,
      valuationDate: allValDates
    };
    let url = environment.apiVersion + "/saf/form/all_versions";
    return this.appHttpRequest("POST", "saf", url, payload).toPromise();
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  getAllDebtFormsVersionsForAllValDates(groupFormId, allValDates) {
    let payload = {
      groupFormId: groupFormId,
      valuationDate: allValDates
    };
    let url = environment.apiVersion + "/debt/form/all_versions";
    return this.appHttpRequest("POST", "saf", url, payload).toPromise();
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  getDebtFormsVersionsForAllValDates(groupFormId, allValDates) {
    let payload = {
      groupFormId: groupFormId,
      valuationDate: allValDates
    };
    let url = environment.apiVersion + "/debt/form/all_versions";
    return this.appHttpRequest("POST", "saf", url, payload);
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  getAllBusinessUnits(consolFormId) {
    let url = environment.apiVersion + "/saf/business-unit/" + consolFormId;
    return this.appHttpRequest("GET", "saf", url, null);
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  powerBIReport(type, orgId) {
    let url = environment.apiVersion + "/powerBIDashboard/getPowerBiEmbedInfo?orgId=" + orgId + "&type=" + type;
    return this.appHttpRequest('GET', 'monitoring', url, null);
  }


  freezeFormVersion(valuationDate, frozenFormId, newFormId) {
    let url = environment.apiVersion + "/saf/form/versions/" +  valuationDate + "/" + frozenFormId + "/" + newFormId;
    return this.appHttpRequest("GET", "saf", url, null);
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  freezeDebtFormVersion(valuationDate, frozenFormId, newFormId) {
    let url = environment.apiVersion + "/debt/form/versions/" +  valuationDate + "/" + frozenFormId + "/" + newFormId;
    return this.appHttpRequest("GET", "saf", url, null);
    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"});
  }

  getInvestmentData(allFormIds) {
    // return this.http.post("http://localhost:8081/api/v1/dd/getAllFormInvestments", allFormIds, {observe: "response"});

    const url = environment.apiVersion + '/dd/getAllFormInvestments';
    return this.appHttpRequest('POST', 'vcp', url, allFormIds);
  }

  getValuationAlgo(selectedCompIds) {
    // return this.http.post("http://localhost:8081/api/v1/dd/getValuationAlgo", selectedCompIds, {observe: "response"});

    const url = environment.apiVersion + '/dd/getValuationAlgo';
    return this.appHttpRequest('POST', 'vcp', url, selectedCompIds);
  }

  getT2Forms(userId) {
    const url = environment.apiVersion + "/dd/vcform/" + userId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getAllSubmittedForms(userId) {
    const url = environment.apiVersion + environment.apiSAFNewForm + "?assessorUserId="+ userId;
    return this.appHttpRequest("GET", "saf", url, null);
  }

  saveFormDetails(details) {
    const url = environment.apiVersion + environment.apiSAFNewForm + "/details";
    return this.appHttpRequest("POST", "saf", url, details);
  }

  downloadExtractedFile(fileId) {

    const url = environment.apiGateway + environment.thirdPartyService + environment.apiVersion + '/file/download/' + fileId;

    // const url = "http://localhost:8805/api" + environment.apiVersion + '/file/download/' + fileId;

    const h = this.getHeaderToken();

    return this.http.get(url, {
      responseType: 'blob',
      headers: h,
      observe: 'response'
    });
  }

  getAllExtractionRequests(userId) {
    const url = environment.apiGateway + environment.thirdPartyService + environment.apiVersion + '/file/te/' + userId;
    // const url = "http://localhost:8805/api" + environment.apiVersion + "/file/te/" + userId;
    return this.http.get(url, {headers: this.getHeaderToken(), observe: "response"})
  }

  uploadTextExtractFile(file: File, desc) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('desc', desc);

    const url = environment.apiGateway + environment.thirdPartyService + environment.apiVersion + "/file/textExtract";
    // const url = "http://localhost:8805/api" + environment.apiVersion + "/file/textExtract";

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'blob',
      headers: this.getHeaderToken()
    });

    // console.log('Request Sent Time', new Date());
    return this.http.request(req);
  }

  upload(file, orgId) {
    const url = environment.apiGateway + environment.safService + environment.apiVersion + '/file/uploadProfileImage?formId=';
    // const url = "http://localhost:8080/api" + environment.apiVersion + '/file/uploadProfileImage?formId=';
    const formdata: FormData = new FormData();

    formdata.append('file', file);

    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: this.getHeaderToken()
    });

    return this.http.request(req);
  }

  download(fileId) {
    const url = environment.apiGateway + environment.safService + environment.apiVersion + '/file/downloadProfileImage/' + fileId;

    const h = this.getHeaderToken();

    return this.http.get(url, {
      responseType: 'blob',
      headers: h,
      observe: 'response'
    });
  }

  downloadExcelFile(input) {
    const url = environment.apiGateway + environment.vcpService + environment.apiVersion + "/downloads/excel"
    // const url = "http://localhost:8081/api/v1/downloads/excel"

    const h = this.getHeaderToken();
    return this.http.post(url, input, {
      responseType: 'blob',
      headers: h,
      observe: 'response'
    });
  }

  downloadExcelFileV2(input) {
    const url = environment.apiGateway + environment.vcpService + environment.apiVersion + "/downloads/excelV2"
    // const url = "http://localhost:8081/api/v1/downloads/excelV2"

    const h = this.getHeaderToken();
    return this.http.post(url, input, {
      responseType: 'blob',
      headers: h,
      observe: 'response'
    });
  }

  getCorporateInfo(company) {
    const url = environment.apiVersion + '/oc/companydetail'
    const body = {
      "name": company.name,
      "opencorporates_url": company.opencorporates_url
    }

    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  getCorporates(name) {
    // https://vcpdevapims.azure-api.net/valuation/api/v1/oc/microsoft
    const url = environment.apiVersion + '/oc/' + name;
    return this.appHttpRequest('GET', 'valuation', url, {});
  }

  deleteCustomAttributeById(attributeId) {
    const url = environment.apiVersion + '/investment-summary/attributes/delete/' + attributeId;
    // return this.http.get("http://localhost:8081/api" + url, {headers: this.getHeaderToken(), observe: "response"});
    return this.appHttpRequest('GET', 'vcp', url, null);
  }

  getContactDetailsByURL(linkedinUrl) {
    const url = environment.apiVersion + '/apl/email?linkedinUrl=' + linkedinUrl;
    // thirdpartyapi/api/v1/apl/email?linkedinUrl={linkedinUrl}
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  getConcludedWaterfallVersion(formId){
    const url = environment.apiVersion + "/waterfall/versions/getConcludedWaterfallWidget?valuationDateId=" + formId;
    // return this.http.post("http://localhost:8081/api" + url, input, {headers: this.getHeaderToken(), observe: "response"});
    return this.appHttpRequest('GET', 'vcp', url, null)
  }

  getConcludedWaterfallVersionForAllIds(formIds){
    const url = environment.apiVersion + "/waterfall/versions/getConcludedWaterfallWidgetForValuationDateIds";
    // return this.http.post("http://localhost:8081/api" + url, formIds, {headers: this.getHeaderToken(), observe: "response"});
    return this.appHttpRequest('POST', 'vcp', url, formIds)
  }

  getMarketSizeEstimateRandomKeyWord(tag: string, countries, uid) {
    const url = environment.apiVersion + '/multimarketsize/' + tag.toUpperCase() + '/' + countries + '?uid=' + uid + '&urlCount=20';
    // https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/multimarketsize/{mltag}/{comma seperated countries}?urlCount=20
    // https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/multimarketsize/{mltag}/{country}?uid={uid}&urlCount={urlCount}
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  getMarketSizeUserRequests(uid, tag) {
    // https://vcpdevapims.azure-api.net/thirdpartyapi/api/v1/multimarketsize/poll/123456/GOLF
    const url = environment.apiVersion + '/multimarketsize/poll/' + uid + '/' + tag
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  getAllMSRequestsByUser(uid) {
    // /api/v1/multimarketsize/poll/{uid}
    const url = environment.apiVersion + '/multimarketsize/poll/' + uid
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  deleteMS(body) {
    const url = environment.apiVersion + '//multimarketsize'
    return this.appHttpRequest('PUT', 'thirdParty', url, body);
  }

  getPA(ipUrl) {
    const url = environment.apiVersion + '/crst/personality-check?url=' + ipUrl;
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  getFounderBackgroundCheck(name, company, linkedInUrl, fetchByURL) {
    const url = environment.apiVersion + '/FP/BackgroundCheck/bctool'

    const body = {
      "name": name, "company": company, "profile": linkedInUrl
    }

    return this.appHttpRequest('POST', 'thirdParty', url, body);
  }

  getFounderWWPResults(caseId, searchType) {
    const url = environment.apiVersion + '/FP/BackgroundCheck/case/' + caseId + '/search/' + searchType + '?type=databind';
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  deleteSavedForm(formId) {
    // const url = "http://localhost:8081/api/v1/forms/deleteSavedForms/" + formId;
    // return this.http.get(url, {observe: "response"});
    const url = environment.apiVersion + "/forms/deleteSavedForms/" + formId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getBugsByUser(userId) {
    const url = environment.apiVersion + '/bugs/' + userId;
    return this.appHttpRequest("GET", "vcp", url, null);

    // const apiGatewayUrl = "http://localhost:8081/api" + url;
    // return this.http.get(apiGatewayUrl, { observe: 'response'});

  }

  getDefaultsForUser(){
    const url = environment.apiVersion + '/valuation/defaults' ;
    return this.appHttpRequest("GET", "valuation", url, null);
  }

   appHttpRequest(type, api, url, body) {
    let h = this.getHeaderToken();
    let apiGatewayUrl = url;
    if(api === "saf") {
      apiGatewayUrl = environment.apiGateway + environment.safService + url;
    } else if (api === 'thirdParty') {
      apiGatewayUrl = environment.apiGateway + environment.thirdPartyService + url;
    } else if (api === 'valuation') {
      apiGatewayUrl = environment.apiGateway + environment.valuationServiceSuffix + url;
    } else if (api === 'debt') {
      apiGatewayUrl = environment.apiGateway + environment.debtModelService + url;
    } else if (api === 'uam') {
      apiGatewayUrl = environment.apiGateway + environment.uamService + url;
    } else if (api === 'approval') {
      apiGatewayUrl = environment.apiGateway + environment.approvalSystemService + url;
    } else if (api === 'monitoring') {
      apiGatewayUrl = environment.apiGateway + '/monitoring/api' + url;
    } else if (api === 'integration') {
      apiGatewayUrl = environment.apiGateway + '/integrations-data-processor/api' + url;
    }  else if (api === 'reporting-v2') {
      apiGatewayUrl = environment.apiGateway + '/reporting-v2/api' + url;
    } else if (api === 'internal-visualization-tool') {
      apiGatewayUrl = environment.apiGateway + '/internal-visualization-tool/api' + url;
    } else if (api === 'pluto-service') {
      apiGatewayUrl = environment.apiGateway + '/pluto-service/api' + url;
      h = h.append('X-USER-ID', this.getUserId());
    } else {
      apiGatewayUrl = environment.apiGateway + environment.vcpService + url;
      // apiGatewayUrl = "http://localhost:8081" + environment.vcpService + url;
    }
    if(api === 'saf' && url.includes("debt-assignments")){
      apiGatewayUrl = this.addruid(apiGatewayUrl);
    }
    switch(type) {
      case "GET": return this.http.get(apiGatewayUrl, {headers: h, observe: 'response'});
      case "POST": return this.http.post(apiGatewayUrl, body, {headers: h, observe: 'response'});
      case "PUT": return this.http.put(apiGatewayUrl, body, {headers: h, observe: 'response'});
      case "DELETE" : return this.http.delete(apiGatewayUrl,{headers: h, observe: 'response'});
      case "FILE" :
        h.append('Content-Type', 'multipart/form-data');
        return this.http.post(apiGatewayUrl, body, { headers: h, observe: 'response' });
    }
  }

  updateLastActiveTime() {
    localStorage.setItem("73S-LastActiveTime", (new Date().getTime()) + "");
  }

  updateLastFormSaveTime() {
    localStorage.setItem("73S-VCP-LastFormSaveTime", (new Date().getTime()) + "");
  }

  setFormType(formType) {
    localStorage.setItem('formType', formType);
    this.formType = formType;
  }

  addruid(apiGatewayUrl) {
    apiGatewayUrl = apiGatewayUrl.trim();
    let ruid = this.getUID();
    ruid += '-L-' + this.ums.getUserDetails()?.userId;
    ruid += '-S-' + this.ums.getSelectedUserDetails()?.userId;
    if (apiGatewayUrl.includes('?')) {
      apiGatewayUrl = apiGatewayUrl + '&ruid=' + ruid;
    } else {
      apiGatewayUrl = apiGatewayUrl + '?ruid=' + ruid;
    }
    return apiGatewayUrl;
  }
  public getUID() {
    let now = Date.now().toString();
    now = now + Math.floor(Math.random() * 1000);
    if (now.length < 16) {
      for (let index = 0; index < 16 - now.length; index++) {
        now = '0' + now;
      }
    }
    return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 16)].join('_');
  }

  getFormType() {
    if(!this.formType) {
      this.formType = localStorage.getItem('formType');
    }

    return this.formType;
  }

  isInstaValForm() {
    if(!this.formType) {
      this.formType = localStorage.getItem('formType');
    }

    return this.formType === "IV";
  }

  isT7Form() {
    if(!this.formType) {
      this.formType = window.localStorage.getItem('formType');
    }
    return this.formType === 'T7';
  }

  isT2Form() {
    if(!this.formType) {
      this.formType = window.localStorage.getItem('formType');
    }
    return this.formType === 'T2';
  }

  getWidgetDataFromDBV2(widgetKey, id) {
    const url = environment.apiVersion + '/dd//widget-v2/get?key=' + widgetKey + '&id=' + id;

    // return this.http.get("http://localhost:8081/api" + url, { observe: 'response' });
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getWidgetDataFromDB(widgetKey, compId) {
    const h = this.getHeaderToken();
    const url = environment.apiGateway + environment.vcpService + environment.apiVersion + '/dd/widget';

    return this.http.get(url, {
      headers: h,
      params: { widgetKey: widgetKey, formId: compId },
      observe: 'response'
    });
  }

  getAllWidgetDataFromDB(widgetKeys, uniqueId) {
    const url = environment.apiVersion + '/dd/getAllWidgets/' + uniqueId;
    return this.appHttpRequest("POST", "vcp", url, widgetKeys);

    // return this.http.post("http://localhost:8081/api" + url, widgetKeys, { observe: 'response' });
  }

  getWidgetDatForAllIds(widgetKey, allIds) {
    const url = environment.apiVersion + '/dd/getWidgetForAllIds/' + widgetKey;
    return this.appHttpRequest("POST", "vcp", url, allIds);

    // return this.http.post("http://localhost:8081/api" + url, allIds, { observe: 'response' });
  }

  getWidgetDataFromDB1(widget) {
    this.updateLastActiveTime();

    const h = this.getHeaderToken();
    const url =
      environment.apiGateway +
      environment.vcpService +
      environment.apiVersion +
      '/dd/widget';
    return this.http.get(url, {
      headers: h,
      params: { widgetKey: widget, formId: this.getFormId() },
      observe: 'response'
    });
  }
  getCCMDefaultAnalysisData() {
    const url = environment.apiVersion + "/valuation/comparable-company/" + this.formId;
    return this.appHttpRequest('GET', 'valuation', url, null);

    // const url = "http://localhost:8807/api/v1/valuation/comparable-company/" + this.formId;
    // return this.http.get(url, {observe: "response"});
  }
  getSimilarCompsByDomain(domain) {
    const url = environment.apiVersion + "/spyfu/2121/competitors/tickers?domain=" + domain;
    return this.appHttpRequest('GET', 'thirdParty', url, null);
  }

  formVersion = 1;
  getCCM_CTMCompanies(body) {
    body["formId"] = this.getFormId();
    body["formVersion"] = this.formVersion;

    const url = environment.apiVersion + "/valuation/ctmccm/similarcompanies";
    return this.appHttpRequest('POST', 'valuation', url, body);

    // const url = "http://localhost:8807/api/v1/valuation/ctmccm/similarcompanies";
    // return this.http.post(url, body, {observe: "response"});
  }
  getSimilarityScoreCCM(description, companies) {
    const body = {
      "data": [
        {   "id":"id",
            "text": description,
            "senteio_ids": companies
        }
      ]
    }
    const url = environment.apiVersion + "/valuation/ctmccm/similarcompanies/scores"
    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  saveWidgetToDB(widgetKey, data, isPost?) {
    this.updateLastActiveTime();
    this.updateLastFormSaveTime();

    const url =
      environment.apiVersion + '/dd/widget/' + widgetKey + '/' + this.formId;
    return this.appHttpRequest('POST', 'vcp', url, JSON.parse(this.utilService.cleanString(JSON.stringify(data))));
  }

  saveWidgetDataToDB(widgetKey, data, userId) {
    const url =
      environment.apiVersion + '/dd/widget/' + widgetKey + '/' + userId;
      return this.appHttpRequest('POST', 'vcp', url, data);
  }

  saveWidgetDataToDBV2(widgetKey, id, data) {
    const reqBody = [{
      "id": id, "key": widgetKey, "widgetData": data
    }]

    const url = environment.apiVersion + '/dd//widget-v2/save';

    // return this.http.post("http://localhost:8081/api" + url, reqBody, {observe: "response"});

    return this.appHttpRequest('POST', 'vcp', url, reqBody);
  }

  saveWidgetToDBAsync(widgetKey, data, id) {
    this.updateLastActiveTime();
    this.updateLastFormSaveTime();

    const url =
      environment.apiVersion + '/dd/widget/' + widgetKey + '/' + id;
    this.appHttpRequest('POST', 'vcp', url, data).subscribe(
      result => {
        console.log('Widget ' + widgetKey + '@@@@@@@@@ data saved successfully');
      },
      error => {
        console.log('Error: Widget ' + widgetKey + ' data save failed', error);
      }
    );
  }

  addAnalysisTracker(algorithm, analysis, changes) {
    const body = { analysis: JSON.stringify(analysis), changes: JSON.stringify(changes) }

    // const url = "http://localhost:8807/api/v1/analysis/tracker/" + this.formId + "/" + algorithm;
    // this.http.post(url, body, {observe: "response"})

    const url = environment.apiVersion + '/analysis/tracker/' + this.getFormId() + "/" + algorithm;
    this.appHttpRequest('POST', 'valuation', url, body)
      .subscribe(result=>{
      // console.log("Tracker is saved", result.body)

      this.utilService.closeAllPopups();
    }, error=>{
      console.log("Failed to save analysis update into tracker", error)

      this.utilService.closeAllPopups();
    })
  }
  getAdjustmentMultipleForCCM(body) {
    body["formId"] = this.getFormId();
    body["formVersion"] = this.formVersion;

    const url = environment.apiVersion + "/valuation/ccm-adjustable-multiples-single-company";
    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  getComparableCompsMarketData(formId, dateInfo) {
    const url = environment.apiVersion + '/valuation/comparableCompsMarketData/' + formId;
    // return this.http.post("http://localhost:8807/api" + url, dateInfo, {observe: "response"});
    return this.appHttpRequest('POST', 'valuation', url, dateInfo);
  }

  getComparableCompsMarketDataNew(dataList) {
    const url = environment.apiVersion + '/valuation/comparableCompsMarketData';
    // return this.http.post("http://localhost:8807/api" + url, {data:dataList}, {observe: "response"});
    return this.appHttpRequest('POST', 'valuation', url, {data:dataList});
  }

  getMultipleEvolutionChartData(reqBody) {
    const url = environment.apiVersion + '/investment-summary/get-multiple-evolution-chart';
    // return this.http.post("http://localhost:8081/api" + url, reqBody, {observe: "response"});
    return this.appHttpRequest('POST', 'vcp', url, reqBody);
  }

  downloadEvolutionChartData(reqBody) {
    const url = environment.apiVersion + '/investment-summary/get-data-for-multiple-evolution-chart-excel-download';
    // return this.http.post("http://localhost:8081/api" + url, reqBody, {observe: "response"});
    return this.appHttpRequest('POST', 'vcp', url, reqBody);
  }

  getMarketDataForGivenDate(valuationDateId, date, currencyCode, companyId) {
    const url = environment.apiVersion + "/valuation/market-data-aggregations-on-given-date/" + valuationDateId + "/" + date + "/" + currencyCode + "/" + companyId;

    // return this.http.get("http://localhost:8807/api" + url, {observe: "response"});
    return this.appHttpRequest('GET', 'valuation', url, null);
  }

  // getCurrencyExchangeRatesLive(sourceCurrency, targetCurrency) {
  //   const url = environment.apiVersion + environment.exchangeRateApi + "?source=" + sourceCurrency + "&target=" + targetCurrency;
  //   return this.appHttpRequest('GET', 'vcp', url, null);
  // }

  updateCurrencyExchangeRate(reqBody) {
    const url = environment.apiVersion + '/valuation/updateExchangeRate'
    return this.appHttpRequest('POST', 'valuation', url, reqBody);

    // return this.http.post("http://localhost:8807/api" + url, reqBody, { observe: "response"});
  }

  getValuationDateCurrency(sourceCurrency, targetCurrency, formValuationDate): any{
    const url = environment.apiVersion + environment.valDateExchangeRateAPI + "?date=" + formValuationDate + "&from=" + sourceCurrency + "&to=" + targetCurrency;
    return this.appHttpRequest('GET', 'valuation', url, null);
  }

  getAllValuationDateCurrency(reqBody): any {
    const url = environment.apiVersion + '/currency/get-currency-rates/all'
    return this.appHttpRequest('POST', 'valuation', url, reqBody);``

    // return this.http.post("http://localhost:8807/api" + url, reqBody, { observe: "response"});
  }

  getCurrencyExchangeRatesByCurrencyLayer(reqBody): any{
    const url = environment.apiVersion + '/valuation/currency-exchange/all'
    return this.appHttpRequest('POST', 'valuation', url, reqBody);

    // return this.http.post("http://localhost:8807/api" + url, reqBody, { observe: "response"});
  }

  getCurrencyDashboard(fundId, activeModule, pageNumber, pageSize){
    const url = environment.apiVersion + '/currency/currency-dashboard/' + fundId + '/' + activeModule + '/' + pageNumber + '/' + pageSize;
    return this.appHttpRequest('GET', 'valuation', url, {});
    // const url = "http://localhost:8807/api/v1/currency/currency-dashboard/" + fundId;
    // return this.http.get(url, {observe: "response"});
  }

  updateCurrencyDashboardFxRate(reqBody){
    const url = environment.apiVersion + '/currency/override-currency-rates/update';

    return this.appHttpRequest('POST', 'valuation', url, reqBody);

    // return this.http.post("http://localhost:8807/api" + url, reqBody, {observe: "response"});
  }

  getCurrencyDashboardAverageType(fundId) {
    const url = environment.apiVersion + '/currency/average-type/get/' + fundId

    return this.appHttpRequest('GET', 'valuation', url, null);

    // return this.http.get("http://localhost:8807/api" + url, {observe: "response"});
  }

  updateAverageType(averageTypeSelection) {
    const url = environment.apiVersion + '/currency/average-type/update'

    return this.appHttpRequest('POST', 'valuation', url, averageTypeSelection);

    // return this.http.post("http://localhost:8807/api" + url, averageTypeSelection, {observe: "response"});
  }

  saveValuationDefaults(body, approach) {
    // const url = "http://localhost:8807/api/v1/valuation/defaults/" + approach;
    // return this.http.post(url, body, { observe: "response"});

    const url = environment.apiVersion + "/valuation/defaults/" + approach;
    return this.appHttpRequest('POST', 'valuation', url, body);
  }

  getBalanceSheet(formId, formVersion) {
    const url = environment.apiVersion + '/valuation/balance-sheet-adjustment/' + formId + '?formVersion=' + formVersion + '&formType=CORP';
    return this.appHttpRequest('GET', 'valuation', url, null);
  }

  getIADefaultAnalysis() {
    const url = environment.apiVersion + '/valuation/income-approach-default-analysis?formId=' + this.getFormId() + '&formType=' + this.getFormType().toUpperCase() + "&formVersion=" + this.formVersion;
    return this.appHttpRequest('GET', 'valuation', url, null);
  }

  getIAInputs(companies) {
    const url = environment.apiVersion + "/valuation/dcf-assumptions-cost-of-capital?formId=" + this.formId + "&formType=CORP&formVersion=" + this.formVersion;
    return this.appHttpRequest('POST', 'valuation', url, companies);
  }
  getIACustomAnalysis(algorithmInput, formId, formVersion) {
    const url = environment.apiVersion + '/valuation/income-approach-re-analysis?formId=' + formId + '&formType=CORP&formVersion=' + formVersion;
    return this.appHttpRequest('POST', 'valuation', url, algorithmInput);
  }

  getIAScenario(reqBody, formId, formVersion) {
    // /valuation/income-approach-scenarios?formId={formId}&formType={formType}&formVersion={formVersion}

    const url = environment.apiVersion + "/valuation/income-approach-scenarios?formId="+ formId + "&formType=corp&formVersion=" + formVersion
    return this.appHttpRequest('POST', 'valuation', url, reqBody);
  }

  getIARangeAnalysis(parameters, formId, formVersion) {
    const url = environment.apiVersion + '/valuation/income-approach-range-algo?formId=' + formId + "&formType=CORP&formVersion=" + formVersion;
    return this.appHttpRequest('POST', 'valuation', url, parameters);
  }

  parseExcel(file) {
    const url = environment.apiGateway + environment.safService + environment.apiVersion + "/excelUpload/upload-financialHistory";
    // const url = "http://localhost:8080/api/v1/excelUpload/upload-financialHistory";
    let formdata: FormData = new FormData();

    formdata.append('file', file);

    const req = new HttpRequest('POST', url , formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: this.getHeaderToken()
    });

    return this.http.request(req);
  }

  saveReviewsTopics(body) {
    const url = environment.apiVersion + "/reviews/save";
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response"});
		return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getSavedReviews() {
    const url = environment.apiVersion + "/reviews/savedReviews";
    return this.appHttpRequest('GET', 'vcp', url, null);
    // return this.http.get("http://localhost:8081/api" + url, { observe: "response" })
  }

  getReviewAnalysis(reviewId) {
    const url = environment.apiVersion + "/reviews/" + reviewId;
    return this.appHttpRequest('GET', 'vcp', url, null);
    // return this.http.get("http://localhost:8081/api" + url, { observe: "response" })
  }


  getAdvanceNavBridgeFilterData(reqBody){
    // https://stringsuatapi.azure-api.net/vcp/api/v1/valuationBridge/get-unique-attribute-values-for-multiple-ids
    const url = environment.apiVersion + '/valuationBridge/get-unique-attribute-values-for-multiple-ids'
    return this.appHttpRequest('POST', 'vcp', url, reqBody);
  }

  getSelectedCompanyOrgDetails(domain) {
    // https://stringsprodapi.azure-api.net/thirdpartyapi/api/v1/apl/organization-and-people?domain={domain}
    const url = environment.apiVersion + '/apl/organization-and-people?domain=' + domain;

    return this.appHttpRequest('GET', 'thirdParty', url, {});
  }

  // getSelectedCompanyOrgDetails(reqBody) {
  //   // https://stringsprodapi.azure-api.net/thirdpartyapi/api/v1/apl/organization-and-people?domain={domain}
  //   //const url = environment.apiVersion + '/apl/organization-and-people?domain=' + reqBody;

  //   //return this.appHttpRequest('GET', 'thirdParty', url, {});
  //   const url = "http://localhost:8805/api/v1/crunchBase/getCompanyDetails";
  //   return this.http.post(url, reqBody, {headers: this.getHeaderToken(), observe: 'response'});
  // }

  saveUpdatedVersionName(body) {

    const url = environment.apiVersion + "/saf/updateVersionName"
    return this.appHttpRequest('POST', 'saf', url, body);

    // return this.http.post("http://localhost:8080/api" + url, body, { observe: "response" })
  }

  getValuationBridgeDataForAllForms(body, fundId, formId){
    const url = environment.apiVersion+"/valuationBridge/getMultipleValueBridge/" + fundId + "/" + formId
    // const url = "/v1/valuationBridge/getMultipleValueBridge/" + fundId + "/" + formId
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getFundLevelVB(fundId, assessorUserId, body){
    const url = environment.apiVersion+"/valuationBridge/fund-level?fundId=" + fundId + "&assessorUserId=" + assessorUserId;
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
    // return of(this.dummy)
  }


  getLogoURLForAllIds(body){
    const url = environment.apiVersion+"/logo/get-logo-url";
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getExitSimulationData(body){
    const url = environment.apiVersion+"/exit-simulation/getFinancials"
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getExitSimulation(body){
    const url = environment.apiVersion+"/exit-simulation/get-simulation-data"
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getAllFunds(user) {
    const url = environment.apiVersion+"/funds/get?userId=" + user.id + "&orgId=" + user.organization.id

    // return this.http.get("http://localhost:8081/api" + url, { observe: "response" })
    return this.appHttpRequest('GET', 'vcp', url, null);
  }

  /********************** Stakeholder Starts **********************/

  dispatch<R, B>(
    type: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'FILE',
    api: 'saf' | 'thirdParty' | 'valuation' | 'debt' | 'uam' | 'monitoring',
    url: string,
    body: B
  ): Observable<HttpResponse<R>> {
    const h = this.getHeaderToken();
    let apiGatewayUrl = url;
    if(api === "saf") {
      apiGatewayUrl = environment.apiGateway + environment.safService + url;
    } else if (api === 'thirdParty') {
      apiGatewayUrl = environment.apiGateway + environment.thirdPartyService + url;
    } else if (api === 'valuation') {
      apiGatewayUrl = environment.apiGateway + environment.valuationServiceSuffix + url;
    } else if (api === 'debt') {
      apiGatewayUrl = environment.apiGateway + environment.debtModelService + url;
    } else if (api === 'uam') {
      apiGatewayUrl = environment.apiGateway + environment.uamService + url;
    } else if (api === 'monitoring') {
      apiGatewayUrl = environment.apiGateway + '/monitoring/api' + url;
    }
    else {
      apiGatewayUrl = environment.apiGateway + environment.vcpService + url;
      // apiGatewayUrl = "http://localhost:8081" + environment.vcpService + url;
    }

    if((api ==='debt' || api === 'saf') && url.includes("debt-assignments")){
      apiGatewayUrl = this.addruid(apiGatewayUrl);
    }

    switch(type) {
      case "GET": return this.http.get<R>(apiGatewayUrl, {headers: h, observe: 'response'});
      case "POST": return this.http.post<R>(apiGatewayUrl, body, {headers: h, observe: 'response'});
      case "PUT": return this.http.put<R>(apiGatewayUrl, body, {headers: h, observe: 'response'});
      case "DELETE" : return this.http.delete<R>(apiGatewayUrl,{headers: h, observe: 'response'});
      case "FILE" :
        h.append('Content-Type', 'multipart/form-data');
        return this.http.post<R>(apiGatewayUrl, body, { headers: h, observe: 'response' });
    }
  }

  getAllFundsForStakeholder(
    user: IUserIdAndOrganizationId
  ): Observable<HttpResponse<ApiResponse<IStakeholderFund[]>>> {
    const url = `${environment.apiVersion}/stakeholders/utils/funds?orgId=${user.organization.id}&userId=${user.id}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  getStakeholderSelections(
    user: IUserIdAndOrganizationId
  ): Observable<HttpResponse<ApiResponse<IStakeholderSelection>>> {
    const url = `${environment.apiVersion}/stakeholders/selections?orgId=${user.organization.id}&userId=${user.id}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  saveStakeholderSelection(
    user: IUserIdAndOrganizationId,
    stakeholderSelection: IStakeholderSelection
  ): Observable<HttpResponse<ApiResponse<IStakeholderSelection>>> {
    const body: {
      fundIds: string[],
      issuerCompanyIds: string[],
      securityDebtModelIds: string[]
    } = {
      fundIds: [...stakeholderSelection.fundIds],
      issuerCompanyIds: [...stakeholderSelection.issuerCompanyIds],
      securityDebtModelIds: [...stakeholderSelection.securityDebtModelIds]
    }
    const url = `${environment.apiVersion}/stakeholders/selections?orgId=${user.organization.id}&userId=${user.id}`;
    return this.dispatch('POST', 'debt', url, body);
  }

  getStakholderAllocationSummary(
    user: IUserIdAndOrganizationId
  ): Observable<HttpResponse<ApiResponse<IStakeholderSummary[]>>> {
    const url = `${environment.apiVersion}/stakeholders/allocations/summary?orgId=${
      user.organization.id
    }&userId=${user.id}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  getStakeholderAllocation(
    user: IUserIdAndOrganizationId,
    fundIds: Set<string> = null
  ): Observable<HttpResponse<ApiResponse<IStakeholderAllocationDetails[]>>> {
    let url = `${environment.apiVersion}/stakeholders/allocations/?orgId=${user.organization.id}&userId=${user.id}`;
    if (fundIds != null && fundIds.size > 0)
      url += `&fundIds=${[...fundIds].join(',')}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  getStakeholderUsers(
    user: IUserIdAndOrganizationId
  ): Observable<HttpResponse<ApiResponse<IStakeholderUser[]>>> {
    const url = `${environment.apiVersion}/stakeholders/users?orgId=${user.organization.id}&userId=${user.id}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  saveStakeholderUser(
    user: IUserIdAndOrganizationId,
    stakeholderUsername: string
  ): Observable<HttpResponse<ApiResponse<IStakeholderUser>>> {
    const url = `${environment.apiVersion}/stakeholders/users`;
    const stakeholderUser: {
      username: string,
      orgId: string,
      userId: string
    } = {
      username: stakeholderUsername,
      orgId: user.organization.id,
      userId: user.id
    }
    return this.dispatch('POST', 'debt', url, stakeholderUser);
  }

  deleteStakeholderUser(stakeholderUserId: string): Observable<HttpResponse<ApiResponse<void>>> {
    const url = `${environment.apiVersion}/stakeholders/users/${stakeholderUserId}`;
    return this.dispatch('DELETE', 'debt', url, null);
  }

  updateStakeholderAllocations(
    stakeholderAllocationDeatils: IStakeholderAllocationDetails[],
    allocationIdsToBeDeleted: Set<string>,
    user: IUserIdAndOrganizationId
  ): Observable<HttpResponse<ApiResponse<IStakeholderAllocationDetails[]>>> {
    let url = `${environment.apiVersion}/stakeholders/allocations?orgId=${user.organization.id}&userId=${user.id}`;
    if (allocationIdsToBeDeleted.size > 0)
      url += `&allocationIdsToDelete=${Array.from(allocationIdsToBeDeleted).join(',')}`;
    return this.dispatch('POST', 'debt', url, stakeholderAllocationDeatils);
  }

  /********************** Stakeholder Ends **********************/

  createFund(body, user) {
    const url = environment.apiVersion+"/funds/create?userId=" + user.id + "&orgId=" + user.organization.id
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getValuationSummary(formIds, userId, formVersion) {

    const url = environment.apiVersion+"/valuation-summary/getValuationAlgo?userId=" + userId + "&version=" + formVersion;

    // return this.http.post("http://localhost:8081/api" + url, formIds, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, formIds);
  }

  getValuationBridgeBizUnits(body, fundId, formId){
    const url = environment.apiVersion+"/valuationBridge/getValueBridgeBU/"+ fundId + "/" + formId
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getAdjustmentsTrackerForConsolSummary(body){
    const url = environment.apiVersion + "/adjustments-tracker/consol-summary"
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getValuationBridgeData(body){
    const url = environment.apiVersion+"/valuationBridge/getValueBridge"
    // return this.http.post("http://localhost:8081/api" + url, body, { observe: "response" })
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getCompanyAndLatestValuationDates(userId, fundId) {
    const url = environment.apiVersion + "/saf/fund/portfolio/" + userId + "/" + fundId;

    // return this.http.get("http://localhost:8080/api" + url, { observe: "response" })
    return this.appHttpRequest('GET', 'saf', url, null);
  }

  crossHoldingValuation(childEntities, fairValue, ownership) {
    const body = {
      childEntities: childEntities,
      fairValue: fairValue,
      ownership: ownership
    };

    // console.log("Cross holding valuation req body", body);

    // return this.http.post("http://localhost:8807/api/v1" + "/corporate/crossholding-valuation", body, {observe: "response"});
    const url = environment.apiVersion + "/corporate/crossholding-valuation";
    return this.appHttpRequest("POST", "valuation", url, body);
  }

  //Start : Added Code to Delete Fund by FundId.
  // deleteFundById(fundId){
  //   const url = environment.apiVersion + '/funds/delete/' + fundId;
  //   return this.appHttpRequest('GET', 'vcp', url, null);

  //   // return this.http.get('http://localhost:8081/api' + url);
  // }

  deleteFundById(fundId){
    const url = environment.apiVersion + '/funds/delete-funds-by-fundId?fundId=' + fundId;
    return this.appHttpRequest('POST', 'vcp', url, null);

    // return this.http.get('http://localhost:8081/api' + url);
  }

  deleteCompanies(companiesToBeDeleted: string[], orgId){
    const url = environment.apiVersion + "/saf/deleteCompanies?orgId=" + orgId;
    return this.appHttpRequest('POST', "saf", url, companiesToBeDeleted);

    // return this.http.post('http://localhost:8080/api' + url, companiesToBeDeleted);
  }

  deleteValuationFromWaterfall(companiesToBeDeleted: string[], issuerCompanyId, valuationDate){
    const url = environment.apiVersion + "/valuation/debt-model/delete-waterfall?issuerCompanyId=" + issuerCompanyId
      + "&valuationDate=" + valuationDate;

    return this.appHttpRequest('DELETE', "debt", url, companiesToBeDeleted);
  }

  deleteDebtForm(issuerCompanyId, valuationDate){
    const url = environment.apiVersion + "/valuation/debt-model/delete-debt-form?issuerCompanyId=" + issuerCompanyId
      + "&valuationDate=" + valuationDate;

    return this.appHttpRequest('DELETE', "debt", url, null);
  }

  //End : Added Code to Delete Fund by FundId.

  createValuationBridge(valuationBridgeData){
    const url = environment.apiVersion + "/valuationBridge/create";

    // return this.http.post("http://localhost:8081/api" + url, valuationBridgeData);
    return this.appHttpRequest("POST", "vcp", url, valuationBridgeData);
  }

  getSavedValuationBridge(startDate, endDate, companyId, centerDate, typeOfValueBridge) {
    const url = environment.apiVersion + "/valuationBridge/getValuationBridge?startDate=" + startDate
      + "&endDate=" + endDate
      + "&companyId=" + companyId
      + "&centerDate=" + centerDate
      + "&typeOfValueBridge=" + typeOfValueBridge;

    // return this.http.get("http://localhost:8081/api" + url, {observe: "response"});
    return this.appHttpRequest("GET", "vcp", url, null);
  }
  updateCurrencyAverage(param: any) {
    // let organization_details:any = localStorage.getItem("73S-User");
    let json: any = {
      fundId: param.fundId,
      source: param.source,
      module: param.module,
      createdBy: param.createdBy,
      lastUpdatedBy: param.lastUpdatedBy
    }
    const url = environment.apiVersion + '/currency/calculate-average/update';
    return this.appHttpRequest('POST', 'valuation', url, json)
  }

  currencyRateCaptured() {
    let organization_details: any = localStorage.getItem("73S-User");
    let org_id: any = JSON.parse(organization_details).organization.id;
    const url = environment.apiVersion + '/currency-config/rate-captured-id/' + org_id + '/key/System';
    return this.appHttpRequest('GET', 'valuation', url, {})
  }

  getApprovalCondition(firmId, companyId, fundId) {
    //Mock response
    // return from(new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(ALL_CONDITIONS);
    //   }, 3000);
    // })).toPromise();

    const url = environment.apiVersion + "/conditions/getConditionMapping?firmId=" + firmId + "&fundId=" + fundId + "&companyId=" + companyId;
    // const urlFragment = "/api" + environment.apiVersion + "/conditions/getConditionMapping?firmId=" + firmId + "&fundId=" + fundId + "&companyId=" + companyId;
    // const url = "http://localhost:8081" + urlFragment;

    // console.log("getApprovalCondition: ", url);

    return this.appHttpRequest("GET", "vcp", url, null).toPromise();
    // return this.http.get(url, { observe: 'response'}).toPromise();
  }

  getApprovalConditionForSubscribe(firmId, companyId, fundId) {
    const url = environment.apiVersion + "/conditions/getConditionMapping?firmId=" + firmId + "&fundId=" + fundId + "&companyId=" + companyId;

    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getApprovalRequest(valDate) {
    //Mock response
    return from(new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(null);
        // APPROVAL
      }, 3000);
    })).toPromise();

    // const url = environment.apiVersion + "/conditions/getAllConditions/"+firmId;

    // return this.appHttpRequest("GET", "vcp", url, null).toPromise();
  }

  getAllGuestUsers(orgId){
    const url = environment.apiVersion + '/users/guestUsers?orgId=' + orgId;
    return this.appHttpRequest('GET', 'uam', url, null);
  }

  getAccessTools(userId){
    const url = environment.apiVersion + '/users/' + userId;
    return this.appHttpRequest('GET', 'uam', url, null);
  }

  getAllApprovalDetails(allRequestIdObject) {
    // return from(new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(null);
    //     // ALL_APPROVALS
    //   }, 3000);
    // })).toPromise();

    const url = environment.apiVersion + "/approvals/getApprovalById";

    // const urlFragment = "/api" + environment.apiVersion + "/approvals/getApprovalById";
    // const url = "http://localhost:8081" + urlFragment;

    // console.log("getApprovalCondition: ", url);

    // return this.appHttpRequest("GET", "vcp", url, null).toPromise();
    // return this.http.post(url, allRequestIdObject, { observe: 'response'}).toPromise();


    return this.appHttpRequest("POST", "vcp", url, allRequestIdObject).toPromise();
  }
  initiateApprovalRequest(requestObj)
  {
    const url = environment.apiVersion + '/approvals/addApprovalRequest';

    // return this.http.post("http://localhost:8081/api" + url, approvalReqData, {observe: "response"}).toPromise();

    return this.appHttpRequest("POST", "vcp", url, requestObj).toPromise();
  }

  recallRequest(recallObj)
  {
    const url = environment.apiVersion + '/approvals/approvalAction';

    // return this.http.post("http://localhost:8081/api" + url, approveData, {observe: "response"}).toPromise();

    return this.appHttpRequest("POST", "vcp", url, recallObj).toPromise();
  }

  approveRequest(approveObj)
  {
    const url = environment.apiVersion + '/approvals/approvalAction';

    // return this.http.post("http://localhost:8081/api" + url, approveData, {observe: "response"}).toPromise();

    return this.appHttpRequest("POST", "vcp", url, approveObj).toPromise();
  }

  rejectRequest(rejectObj)
  {
    const url = environment.apiVersion + '/approvals/approvalAction';

    // return this.http.post("http://localhost:8081/api" + url, rejectData, {observe: "response"}).toPromise();

    return this.appHttpRequest("POST", "vcp", url, rejectObj).toPromise();
  }

  getCheckListValuesForCompanyCheckList(id){
    const url = environment.apiVersion + "/approval-matrix-checklist/find-by-valuationDateId?valuationDateId=" + id;
    return this.appHttpRequest('GET', 'vcp', url, null);
  }

  getSaveCompanyCheckList(body){
    const url = environment.apiVersion + "/approval-matrix-checklist/save";
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getCompaniesForCheckList(fundId, userId){
    const url = environment.apiVersion + "/saf/find-frozen-valuation-dates-by-limit-by-fund-id/" + fundId + '?userId=' + userId;
    return this.appHttpRequest('GET', 'saf', url, null);
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response" })
  }

  getNavigationLabelsForCompanyCheckList(){
    const url = environment.apiVersion+"/approval-matrix-checklist/retrieveAll"
    return this.appHttpRequest('GET', 'vcp', url, null);
  }

  getExcelDataForCompaniesInCheckList(companyId, fundId){
    const url = environment.apiVersion + "/investment-summary/get-valuation-summary-excel-data?companyId=" + companyId + "&fundId=" + fundId;
    return this.appHttpRequest('GET', 'vcp', url, null);
    // return this.http.get("http://localhost:8081/api" + url, { observe: "response" })
  }

  getDocumentStatus(approvalObjectId){
    const url = environment.apiVersion + "/get_document_status?approvalObjectId=" + approvalObjectId;
    return this.appHttpRequest('GET', 'approval', url, null);
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response" })
  }

  getCompaniesForCheckListBeta(body){
    const url = environment.apiVersion + "/saf/find-frozen-valuation-dates-by-fund-id-with-approvals";
    return this.appHttpRequest('POST', 'saf', url, body);
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response" })
  }

  getApprovalObjectStatus(reqBody){
    const url = environment.apiVersion + '/get_approval_object_status'
    return this.appHttpRequest('POST', 'approval', url, reqBody);
  }

  getReportTypesByOrgIdReportLevel(orgId, reportLevel){
    const url = environment.apiVersion + '/report/get-report-types-by-orgId-and-report-level?orgId=' + orgId + '&reportLevel=' + reportLevel;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  getReportsBySource(companyId, date, source, reportType, userId){
    const url = environment.apiVersion + '/report/get-reports-by-source?companyId=' + companyId + '&date=' + date + '&source=' + source + '&reportType=' + reportType + '&userId=' + userId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  createNewReport(payload){
    const url = environment.apiVersion + '/report/create';
    return this.appHttpRequest("POST", "vcp", url, payload);
  }

  generateReportData(reportId){
    const url = environment.apiVersion + '/dashboard-report/multi-fund-level?reportId=' + reportId;
    return this.appHttpRequest("POST", "vcp", url, null);
  }

  getGroupById(groupId)
  {
    const url = environment.apiVersion + '/groups/getGroupById?groupId=' + groupId;
    return this.appHttpRequest("GET", "vcp", url, null).toPromise();
  }

  getGroupByIdForSubscribe(groupId)
  {
    const url = environment.apiVersion + '/groups/getGroupById?groupId=' + groupId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  uploadDocument(file, details) {
    // const url = environment.apiGateway + environment.safService + environment.apiVersion + environment.apiUploadFile +
    //   '?formId=' + this.getFormId() + "&user=" + user;

    // const url = "http://localhost:8081/api" + environment.apiVersion + '/files/store';
    const url = environment.apiGateway + environment.vcpService + environment.apiVersion + '/files/store';

    const formdata: FormData = new FormData();

    formdata.append('file', file);
    formdata.append("details", details)

    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: this.getHeaderToken()
    });

    return this.http.request(req);
  }

  getUploadedFiles(formId) {
    // const url = "http://localhost:8081/api" + environment.apiVersion + '/files/' + this.getFormId();
    // return this.http.get(url, {observe: "response"});

    const url = environment.apiVersion + '/files/' + formId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }
  listCreditRatingsFileUploads(fundId) {
    const url = environment.apiVersion + "/valuation/debt-model/credit-rating-upload/list-file-uploads?fundId=" + fundId;
    return this.appHttpRequest('GET', 'debt', url, null);

    // const url = "http://localhost:8808/api" + environment.apiVersion + "/valuation/debt-model/credit-rating-upload/list-file-uploads?fundId=" + fundId;
    // return this.http.get(url, {observe: "response"});
  }

  uploadCreditRatingsFile(file, fundId, date) {
    var form = new FormData();
    form.append("file", file, file.name);

    const url = environment.apiVersion + "/valuation/debt-model/credit-rating-upload/upload-file?dateUploaded="+date+"&fundId="+fundId;
    return this.appHttpRequest('FILE', 'debt', url, form);
  }

  getDeletedFiles(formId){
    const url = environment.apiVersion + '/files/deleted-files/' + formId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  deleteFile(fileId){
    const url = environment.apiVersion + '/files/delete/' + fileId;
    return this.appHttpRequest("GET", "vcp", url, null);
  }

  addNewValuationDate(body) {
    const url = "http://localhost:8080/api/v1/saf/addNewValuationDate";
     return this.http.post(url, body, {observe: "response",headers: this.getHeaderToken()});
  }

  getDashboardPresentation(formIds) {
    const url = environment.apiVersion + '/forms/fetchFormDataFromFormIDs';
    return this.appHttpRequest("POST", "vcp", url, formIds);

    // return this.http.post("http://localhost:8081/api" + url, formIds, {observe: "response"});
  }

  getTrackRecord(reqBody){
    const url = environment.apiVersion + '/metrics/track-record';
    return this.appHttpRequest('POST', "vcp", url, reqBody);
  }

  getValuationDateFormsWithAllVersionByCompanyId(companyId){
    const url = environment.apiVersion + '/saf/versions/valuation-dates/' + companyId;
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response"});
    return this.appHttpRequest('GET', "saf", url, null);
  }

  currencyDashboardConfig(fundId) {
    const url = environment.apiVersion + "/currency-config/rate-captured-id/" + fundId + "/key/SOURCE";
    return this.appHttpRequest('GET', 'valuation', url, {});
  }

  getValuationDateFormsWithAllVersionByCompanyIdAndUserId(companyId, userId){
    const url = environment.apiVersion + '/saf/versions/valuation-dates-v2/' + companyId+ "/" + userId;
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response"});
    return this.appHttpRequest('GET', "saf", url, null);
  }

  getAllValuationDateVersionsByCompanyIdAndUserIdAndOrgId(companyId, userId, orgId){
    const url = environment.apiVersion + '/saf/versions/valuation-dates-v2/' + companyId + "/" + userId + "/" + orgId;
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response"});
    return this.appHttpRequest('GET', "saf", url, null);
  }

  updateSelectedApprovalConditionForEachFormId(approvalConditionId, formId){
    const url = environment.apiVersion + '/saf/updateApprovalCondition?approvalConditionId=' + approvalConditionId + '&formId=' + formId;
    // return this.http.get("http://localhost:8080/api" + url, { observe: "response"});
    return this.appHttpRequest('POST', "saf", url, null);
  }

  getSummaryOfFormsByFormIds(formIds){
    const url = environment.apiVersion + '/saf/valuation-dates/summary';
    // return this.http.post("http://localhost:8080/api" + url, formIds, { observe: "response"});
    return this.appHttpRequest('POST', "saf", url, formIds);
  }

  getSummaryOfFormsByFormIdsV2(formIds, fundId){
    const url = environment.apiVersion + '/saf/valuation-dates/summary-v2?fundId=' + fundId;
    // return this.http.post("http://localhost:8080/api" + url, formIds, { observe: "response"});
    return this.appHttpRequest('POST', "saf", url, formIds);
  }


  getCompanyDetailsForCompanyMaster(reqBody){
    const url = environment.apiVersion + '/saf/all_companies_in_funds';
    return this.appHttpRequest('POST', "saf", url, reqBody);
  }

   updateCompanyDetailsForCompanyMaster(reqBody){
    const url = environment.apiVersion + '/saf/update-company-info';
    return this.appHttpRequest('POST', "saf", url, reqBody);
   }

   updateexternalIdForCompanyMaster(reqBody){
    const url = environment.apiVersion + '/equity-company-master';
    return this.appHttpRequest('POST', "integration", url, reqBody);
   }

   getexternalIdForCompanyMaster(companyId) {
    const url = environment.apiVersion + `/equity-company-master/${companyId}`;
    return this.appHttpRequest('GET', "integration", url, null);
   }

  getVersionComparison(reqBody){
    const url = environment.apiVersion + "/version/comparison/valuation-summary";
    // return this.http.post("http://localhost:8081/api" + url, reqBody, { observe: "response"});
    return this.appHttpRequest('POST', "vcp", url, reqBody);
  }

  getNewMatchingCompanyNames(value) {
    const url = "/v1/typeAhead/matchingCompanyFromMaster?companyName=" + value;
    // return this.http.get("http://localhost:8807/api" + url, { observe: "response"});

    return this.appHttpRequest('GET', 'valuation', url, {});
  }

  getfundAllocationDetails(fundId) {
    const url = environment.apiVersion + "/valuation/debt-model/getAllSecuritiesInFund?fundId=" + fundId;
    return this.appHttpRequest('GET', 'debt', url, {});
  }

  updateStakeAllocations(requestBody) {
    const url = environment.apiVersion + "/valuation/debt-model/updateStakeAllocations";
    return this.appHttpRequest('POST', 'debt', url, requestBody);
  }

  getStakeAllocations(userId, orgId){
    const url = environment.apiVersion + "/valuation/debt-model/getStakeAllocations?&userId=" + userId + "&orgId=" + orgId;
    return this.appHttpRequest('GET', 'debt', url, {});
  }

  getStakeAllocationSummary(userId){
    const url = environment.apiVersion + "/valuation/debt-model/getStakeAllocationSummary?userId=" + userId;
    return this.appHttpRequest('GET', 'debt', url, {});
  }

  getDistinctValDates(reqBody){
    const url = environment.apiVersion + "/saf/distinct-valuation-dates-in-funds";
    return this.appHttpRequest('POST', 'saf', url, reqBody);

    // return this.http.post("http://localhost:8080/api" + url, reqBody, { observe: "response" });
  }

  downloadFile(fileId) {
    const url =
      environment.apiGateway +
      environment.vcpService +
      environment.apiVersion +
      environment.apiDownload +
      '/' +
      fileId;

    const h = this.getHeaderToken();
    return this.http.get(url, {
      responseType: 'blob',
      headers: h,
      observe: 'response'
    });
  }


      // CCA Dynamic Liquidation preference api calls
      getDynamicLP(payload) {
      // const url = environment.apiGateway + '/vcp/api/v1/cca/getDynamicLP';
      // const url = "http://localhost:8807/api/v1/cca/waterfall/getDynamicLP";
      const url = environment.apiGateway + '/valuation/api/v1/cca/waterfall/getDynamicLP';
        return this.http.post(url, payload,
          {
            reportProgress: true,
            responseType: 'json',
            headers: this.getHeaderToken()
          });
      }

    // CCA Dynamic Dividend api calls
    getDynamicDividend(payload) {
      // const url = environment.apiGateway + '/vcp/api/v1/cca/getPreferredDividend';
     // const url = "http://localhost:8807/api/v1/coupon/getPreferredDividend"
    const url = environment.apiGateway + '/valuation/api/v1/coupon/getPreferredDividend';
      return this.http.post(url, payload,
        {
          reportProgress: true,
          responseType: 'json',
          headers: this.getHeaderToken()
        });
    }


      // CCA Liquidation preference api calls
  postLPAnalysis(payload) {
    // const url = environment.apiGateway + '/vcp/api/v1/cca/analyse';
    // const url = "http://localhost:8807/api/v1/cca/waterfall/analyse"
    const url = environment.apiGateway + '/valuation/api/v1/cca/waterfall/analyse';
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  getCCARollOver(body){
    const url = environment.apiVersion + "/cca/rollover"
    return this.appHttpRequest("POST", "valuation", url, body);
  }

  getDateYYYYMMDD(inputDate) {
    const dateParts = inputDate.split("-");

    return this.getDateYYYYMMDDFromUnits(dateParts[0], dateParts[1], dateParts[2]);
  }

  getDateYYYYMMDDFromDate(date: Date) {
    let dateStr = date.getFullYear() + "-";
    const month = date.getMonth() + 1;
    if(month < 10) {
      dateStr += "0";
    }
    dateStr += month + "-";
    const dayInMonth = date.getDate();
    if(dayInMonth < 10) {
      dateStr += "0";
    }
    dateStr += dayInMonth;
    return dateStr;
  }

  getDateYYYYMMDDFromUnits(year, monthInput, date) {
    let dateStr = year + "-";

    const month = +monthInput;

    if(month < 10) {
      dateStr += "0";
    }

    dateStr += month + "-";

    const dayInMonth = +date;

    if(dayInMonth < 10) {
      dateStr += "0";
    }

    dateStr += dayInMonth;

    return dateStr;
  }

  postNormalOptionsAnalysis(payload){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/analyse`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/normaloptions/analyse`;
    // const url = "http://localhost:8807/api/v1/cca/normaloptions/analyse";
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  postSaveNormalOptonInputs(payload){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/save-analysis`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/normaloptions/save-analysis`;
    // const url = "http://localhost:8807/api/v1/cca/normaloptions/save-analysis";
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  getNormalOptionInputs(companyId){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/fetch-analysis?companyId=${companyId}&widgetKey=normaloptions`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/normaloptions/fetch-analysis?formId=${companyId}`;
    // const url = `http://localhost:8807/api/v1/cca/normaloptions/fetch-analysis?formId=${companyId}`;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  postManagementOptionsAnalysis(payload){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/analyse`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/managementoptions/analyse`;
    // const url = "http://localhost:8807/api/v1/cca/managementoptions/analyse"
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  postSaveManagementOptonInputs(payload){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/save-analysis`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/managementoptions/save-analysis`;
    // const url = "http://localhost:8807/api/v1/cca/managementoptions/save-analysis";
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  getManagementOptionInputs(companyId){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/fetch-analysis?companyId=${companyId}&widgetKey=managementoption`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/managementoptions/fetch-analysis?formId=${companyId}`;
    // const url = `http://localhost:8807/api/v1/cca/managementoptions/fetch-analysis?formId=${companyId}`;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  postSalesBonusPoolAnalysis(payload){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/analyse`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/salesbonuspool/analyse`;
    // const url = "http://localhost:8807/api/v1/cca/salesbonuspool/analyse";
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  postSaveSaleBOnusPoolInputs(payload){
    // const url = environment.apiGateway + `/vcp/api/v1/cca/save-analysis`;
    const url = environment.apiGateway + `/valuation/api/v1/cca/salesbonuspool/save-analysis`;
    // const url = "http://localhost:8807/api/v1/cca/salesbonuspool/save-analysis";
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  getSaleBonusPoolInputs(companyId){
    const url = environment.apiGateway + `/valuation/api/v1/cca/salesbonuspool/fetch-analysis?formId=${companyId}`;
    // const url = `http://localhost:8807/api/v1/cca/salesbonuspool/fetch-analysis?formId=${companyId}`;
    // const url = environment.apiGateway + `/vcp/api/v1/cca/fetch-analysis?companyId=${companyId}&widgetKey=salesbonuspool`;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  getManPackAnalysis(manpackAnalysisInput) {
    const url = environment.apiVersion + '/cca/manpack/analyse';
    return this.appHttpRequest('POST', 'valuation', url, manpackAnalysisInput);
  }

  postExcelManpackAnalysis(payload) {
    const url = environment.apiGateway + `/vcp/api/v1/cca/getDilutedStakePostManpack`;
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }


  getAllDebtForms(userId, fundId, orgId?) {
    let url = environment.apiVersion + "/debt/form" + "?assessorUserId="+ userId;
    if(fundId) { url += "&fundId=" + fundId }
    if(orgId) { url += "&orgId=" + orgId }

    return this.appHttpRequest("GET", "saf", url, null).toPromise();

    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"})
  }

  getDebtPortfolioSummaryLocal(payload){
    //const url = environment.apiVersion + "/valuation/debt-model/fundDetailsOfCompanies/getAllSecuritiesDetails";
    const url = environment.apiVersion + "/integration-apis/getAllSecuritiesDetails";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();

    //return this.http.post("http://localhost:8808/api" + url, payload,{observe: "response"}).toPromise();
  }

  getDebtPortfolioSummaryReporting(payload){
    //const url = environment.apiVersion + "/valuation/debt-model/fundDetailsOfCompanies/getPortfolioSummary/getAllSecuritiesDetailsReportingCurrency";
    const url = environment.apiVersion + "/integration-apis/getAllSecuritiesDetailsReportingCurrency";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();

    //return this.http.post("http://localhost:8808/api" + url, payload,{observe: "response"}).toPromise();
  }

  createDebtModelMetaData(payload) {
    const url = environment.apiVersion + "/valuation/debt-model/createMetaData";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();
  }

  getDebtCompanyDetails(companyId): any {
    const url = environment.apiVersion + "/valuation/debt-model/companyDetails/" + companyId;
    return this.appHttpRequest('GET', 'debt', url, null);

    // return this.http.get("http://localhost:8808/api/v1/valuation/debt-model/companyDetails/" + companyId, { observe: "response"}).toPromise();;

  }

  getCovenantsData(payload){
        //Mock response
    // return from(new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(COVENANTS_DATA);
    //   }, 1000);
    // })).toPromise();

    const url = environment.apiVersion + "/valuation/debt-model/getCovenantsData";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();
  }

  saveCovenantMaxValue(payload) {
    const url = environment.apiVersion + "/valuation/debt-model/setMaxValue";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();
  }

  getDebtValuationDetails(payload) {
    //const url = environment.apiVersion + "/valuation/debt-model/valuationDetails/getAllValuationDateFormsDetails";
    const url = environment.apiVersion + "/integration-apis/getAllValuationDateFormsDetails";
    //const url = "/integration-apis/getAllValuationDateFormsDetails";
    return this.appHttpRequest('POST', 'debt', url, payload).toPromise();

    //return this.http.post("http://localhost:8808/api/v1" + url, payload, { observe: "response"}).toPromise();
  }

  getDebtModelId(valuationDateId){
    const url = environment.apiVersion + "/valuation/debt-model/debtdetails/" + valuationDateId ;
    return this.appHttpRequest('GET', 'debt', url, null).toPromise();
  }

  deleteDebtCompanies(companiesToBeDeleted: string[]){
    const url = environment.apiVersion + "/debt/deleteCompanies";
    return this.appHttpRequest('POST', "saf", url, companiesToBeDeleted);

    // return this.http.post('http://localhost:8080/api' + url, companiesToBeDeleted);
  }

  rolloverDebtCompanies(payload){
    const url = environment.apiVersion + "/valuation/debt-model/rollOver";
    return this.appHttpRequest('POST', "debt", url, payload).toPromise();

    // return this.http.post("http://localhost:8808/api/v1//valuation/debt-model/rollOver", payload, { observe: "response"}).toPromise();
  }

  getIssuerCompanies(formId) {
    const url = environment.apiVersion + "/debt/getIssuerCompanies?fundId=" + formId;
    return this.appHttpRequest("GET", "saf", url, {});
  }

  getIssuerCompanyFromGeneralDetail(payload) {
    const url = environment.apiVersion + "/valuation/debt-model/getIssuerCompanyFromGeneralDetails";
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getAllIssuerForms(formId) {
    const url = environment.apiVersion + "/debt/getAllIssuerForms?fundId=" + formId;
    return this.appHttpRequest("GET", "saf", url, {});
  }

  getIssuerCompanyId(formId) {
    const url = environment.apiVersion + "/debt/getIssuerCompanyId?formId=" + formId;
    return this.appHttpRequest("GET", "saf", url, {});
  }
  getIssuerPortfioliSummary(body) {
    //const url = environment.apiVersion + "/valuation/debt-model/getSecuritiesByIssuerCompanyDetails";
    const url = environment.apiVersion + "/integration-apis/getSecuritiesByIssuerCompanyDetails";
    return this.appHttpRequest("POST", "debt", url, body);

    //return this.http.post("http://localhost:8808/api" + url, body, {observe: "response"})
  }
  getIssuerPortfioliSummaryGrossIRR(body) {
    //const url = environment.apiVersion + "/valuation/debt-model/getIssuerPortfioliSummaryGrossIRR";
    const url = environment.apiVersion + "/integration-apis/getIssuerPortfioliSummaryGrossIRR";
    return this.appHttpRequest("POST", "debt", url, body);

    //return this.http.post("http://localhost:8808/api" + url, body, {observe: "response"})
  }
  getIssuerConsolSummary(body) {
    // const url = environment.apiVersion + "/valuation/debt-model/getAllSecuritiesInIssuerCompanyDetails";
    const url = environment.apiVersion + "/integration-apis/getAllSecuritiesInIssuerCompanyDetails";
    return this.appHttpRequest("POST", "debt", url, body);

    //return this.http.post("http://localhost:8808/api" + url, body, {observe: "response"})
  }

  getConsolidatedSummaryCharts(body, issuerCompanyId) {
    const url = environment.apiVersion + "/valuation/debt-model/getConsolidatedSummaryChartData?issuerCompanyId=" + issuerCompanyId;
    return this.appHttpRequest("GET", "debt", url, null);
  }

  getConsolidatedCommentary(issuerCompanyId, body) {
    const url = environment.apiVersion + "/issuerCompany/saveCommentaryInConsolidatedSummary/" + issuerCompanyId;
    return this.appHttpRequest("POST",'debt', url, body);
  }
  getIssuerPortfiolioSummaryTotal(body) {
    const url = environment.apiVersion + "/valuation/debt-model/getIssuerPortfiolioSummaryTotal";
    return this.appHttpRequest("POST", "debt", url, body);
  }
  getIssuerConsolSummaryTotal(body) {
    //const url = environment.apiVersion + "/valuation/debt-model/getIssuerConsolSummaryTotal";
    const url = environment.apiVersion + "/integration-apis/getIssuerConsolSummaryTotal";
    return this.appHttpRequest("POST", "debt", url, body);
  }

  getSecuritiesByIssuerCompany(userId, fundId, orgId?) {
    let url = environment.apiVersion + "/debt/getSecuritiesByIssuerCompany" + "?assessorUserId="+ userId;
    if(fundId) { url += "&fundId=" + fundId }
    if(orgId) { url += "&orgId=" + orgId }

    return this.appHttpRequest("GET", "saf", url, null);
    //return this.http.get("http://localhost:8080/api" + url, {observe: "response"})
  }

  getSecuritiesByIssuerCompanyWithVD(fundId: string, valuationDate: string) {
    const url = `${environment.apiVersion}/debt/getIssuerCompaniesWithValuationDates?fundId=${
      fundId}&valuationDate=${valuationDate}`;
    return this.appHttpRequest('GET', 'saf', url, null);
  }

  getAllSecuritiesForPortfolio(userId, fundId, orgId?) {
    let url = environment.apiVersion + "/debt/getAllSecurities" + "?assessorUserId="+ userId;
    if(fundId) { url += "&fundId=" + fundId }
    if(orgId) { url += "&orgId=" + orgId }

    return this.appHttpRequest("GET", "saf", url, null);
    //return this.http.get("http://localhost:8080/api" + url, {observe: "response"})
  }

  getAllSecuritiesForPortfolioWithVD(
    userId: string, fundId: string, valuationDate: string, orgId: string
  ) {
    const url = `${environment.apiVersion}/debt/getValuationDateFormsOnly?fundId=${
      fundId
    }&valuationDate=${valuationDate}&assessorUserId=${userId}&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'saf', url, null);
  }

  getAllLogos(userId, fundId, orgId?) {
    let url = environment.apiVersion + "/debt/getAllLogos" + "?assessorUserId="+ userId;
    if(fundId) { url += "&fundId=" + fundId }
    if(orgId) { url += "&orgId=" + orgId }

    return this.appHttpRequest("GET", "saf", url, null);
  }

  getAllSecuritiesInIssuerCompany(userId, fundId, issuerCompanyId, orgId?) {
    let url = environment.apiVersion + "/debt/getAllSecuritiesInIssuerCompany" + "?assessorUserId="+ userId;
    if(fundId) { url += "&fundId=" + fundId }
    if(orgId) { url += "&orgId=" + orgId }
    if(issuerCompanyId) { url += "&issuerCompanyId=" + issuerCompanyId }

    return this.appHttpRequest("GET", "saf", url, null);
  }

  getAllValuationDateForms(userId, fundId, companyId, orgId?) {
    let url = environment.apiVersion + "/debt/getAllValuationDateForms" + "?assessorUserId="+ userId;
    if(fundId) { url += "&fundId=" + fundId }
    if(orgId) { url += "&orgId=" + orgId }
    if(companyId) { url += "&companyId=" + companyId }

    return this.appHttpRequest("GET", "saf", url, null);
  }

  getEquityForm(issuerCompanyId) {
    const url = environment.apiVersion + "/saf/getForm?issuerCompanyId=" + issuerCompanyId;
    return this.appHttpRequest("GET", "saf", url, {}).toPromise();
  }

  getEquityFormWithValDate(issuerCompanyId, valuationDate) {
    const url = environment.apiVersion + "/valuation/debt-model/equity-form-status?issuerCompanyId=" + issuerCompanyId + "&valuationDate="+valuationDate;
    return this.appHttpRequest("GET", "debt", url, {}).toPromise();

    //const url = "http://localhost:8808/api/v1/valuation/debt-model/equity-form-status?issuerCompanyId=" + issuerCompanyId + "&valuationDate="+valuationDate;
    //return this.http.get(url, {observe: "response"}).toPromise();
  }

  getAllSecurities(companyId,valuationDate, waterfallId) {
    const url = environment.apiVersion + `/valuation/debt-model/getAllSecurities?issuerCompanyId=${companyId}&valuationDate=${valuationDate}&waterfallId=${waterfallId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/getAllSecurities?issuerCompanyId=${companyId}&valuationDate=${valuationDate}&waterfallId=${waterfallId}`;
    // return this.http.get(url);
  }

  getGoingConcernPresentValue(companyId: string, payload, waterfallId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGoingConcernPresentValue?waterfallId=${waterfallId}`;
    // const url = `http://localhost:8808/api/v1/valuation/debt-model/getGoingConcernPresentValue?waterfallId=${waterfallId}`;
    // return this.http.post(url, payload);
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  updateGoingConcernPresentValue(companyId: string, payload, waterfallId: string) {
    const url = environment.apiVersion + `/valuation/debt-model/updateGoingConcernPresentValue?waterfallId=${waterfallId}`;
    // const url = `http://localhost:8808/api/v1/valuation/debt-model/updateGoingConcernPresentValue?waterfallId=${waterfallId}`;
    // return this.http.post(url, payload);
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  calculateGoingConcernInput(payload, valuationDate, waterfallId){
    const url = environment.apiVersion + `/valuation/debt-model/calculateGoingConcernInputs?valuationDate=${valuationDate}&waterfallId=${waterfallId}`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/calculateGoingConcernInputs?valuationDate=${valuationDate}&waterfallId=${waterfallId}`;
    // return this.http.post(url, payload);
  }

  getGoingConcernInputs(issuerCompanyId, valuationDate, waterfallId: string){
    const url = environment.apiVersion + `/valuation/debt-model/getGoingConcernInputs?waterfallId=${waterfallId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/getGoingConcernInputs?waterfallId=${waterfallId}`;
    // return this.http.get(url);
  }

  saveGoingConcernInputs(issuerCompanyId, payload, valuationDate: string, waterfallId: string){
    const url = environment.apiVersion + `/valuation/debt-model/saveGoingConcernInputs?waterfallId=${waterfallId}`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/saveGoingConcernInputs?waterfallId=${waterfallId}`;
    // return this.http.post(url, payload);
  }

  calculateGoingConcernWaterfall(issuerCompanyId, payload, companyId: string, waterfallId: string){
    const url = environment.apiVersion + `/valuation/debt-model/calculateGoingConcernWaterfall?waterfallId=${waterfallId}`;
    // const url = `http://localhost:8808/api/v1/valuation/debt-model/calculateGoingConcernWaterfall?waterfallId=${waterfallId}`;
    // return this.http.post(url, payload);
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getGoingConcernWaterfall(issuerCompanyId, valuationDate: string, companyId: string,waterfallId: string){
    const url = environment.apiVersion + `/valuation/debt-model/getGoingConcernWaterfall?waterfallId=${waterfallId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/getGoingConcernWaterfall?waterfallId=${waterfallId}`;
    // return this.http.get(url);
  }

  getVolitilityOptions(formId) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/getVolitilityOptions/?formId=${formId}`;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  getVolitility(formId) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/getAnnualizedVolatility/?formId=${formId}`;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  saveAnnualizedVolitility(formId,payload) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/saveAnnualizedVolatility?formId=${formId}`;
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  recalculateVolitility(formId,payload) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/calculateAnnualizedVolatility?formId=${formId}`;
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  getSimilarCompaniesData(value, valuationDate) {
    const url = environment.apiGateway + '/valuation/api/v1/typeAhead/similarCompanies/' + value + '/' +valuationDate;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  getRiskFreeRate(formId) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/getRiskFreeRate?formId=${formId}`;
    return this.http.get(url,
      {
        headers: this.getHeaderToken()
      });
  }

  calculateRiskFreeRate(formId,payload) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/calculateRiskFreeRate?formId=${formId}`;
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
  }

  saveRiskFreeRate(formId,payload) {
    const url = `${environment.apiGateway}/vcp/api/v1/cca/saveRiskFreeRate?formId=${formId}`;
    return this.http.post(url, payload,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getHeaderToken()
      });
    }

  getLeverageRatioData(orgId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGlobalLeverageRatioGrid?orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/getGlobalLeverageRatioGrid?orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});
  }

  saveLeverageRatioData(orgId, payload) {
    const url = environment.apiVersion + `/valuation/debt-model/saveGlobalLeverageRatioGrid?orgId=${orgId}`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/saveGlobalLeverageRatioGrid?orgId=" + orgId;
    // return this.http.post(url, payload);
  }

  getGlobalClassification(orgId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGlobalClassification?orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }

  saveGlobalClassification(orgId, payload) {
    const url = environment.apiVersion + `/valuation/debt-model/saveGlobalClassification?orgId=${orgId}`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getGlobalFairValueConclusion(orgId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGlobalFairValueConclusion?orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/getGlobalFairValueConclusion?orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});

  }

  saveGlobalFairValueConclusion(orgId, payload) {
    const url = environment.apiVersion + `/valuation/debt-model/saveGlobalFairValueConclusion?orgId=${orgId}`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/saveGlobalFairValueConclusion?orgId=" + orgId;
    // return this.http.post(url, payload);
  }

  getGlobalPercentParComputation(orgId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGlobalPercentParComputation?orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/getGlobalPercentParComputation?orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});

  }

  saveGlobalPercentParComputation(orgId, payload) {
    const url = environment.apiVersion + `/valuation/debt-model/saveGlobalPercentParComputation?orgId=${orgId}`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/saveGlobalPercentParComputation?orgId=" + orgId;
    // return this.http.post(url, payload);
  }

  getPriceRounding(orgId) {
    const url = environment.apiVersion + `/org-config/get?configKey=PRICE_ROUNDING&orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }

  savePriceRound(payload) {
    const url = environment.apiVersion + `/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getGlobalDefaultCreditQuality(orgId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGlobalDefaultCreditQuality?orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }

  saveGlobalDefaultCreditQuality(orgId, payload) {
    const url = environment.apiVersion + `/valuation/debt-model/saveGlobalDefaultCreditQuality?orgId=${orgId}`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getGlobalCallPremiumCap(orgId) {
    const url = environment.apiVersion + `/valuation/debt-model/getGlobalCallPremiumCap?orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/getGlobalCallPremiumCap?orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});
  }

  saveGlobalCallPremiumCap(orgId, payload) {
    const url = environment.apiVersion + `/valuation/debt-model/saveGlobalCallPremiumCap?orgId=${orgId}`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/valuation/debt-model/saveGlobalCallPremiumCap?orgId=" + orgId;
    // return this.http.post(url, payload);
  }

  getForwardLag(orgId) {
    const url = environment.apiVersion + `/org-config/get?configKey=LAG_IN_FORWARD_BASE&orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/org-config/get?configKey=LAG_IN_FORWARD_BASE&orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});
  }

  saveForwardLag(payload) {
    const url = environment.apiVersion + `/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/org-config/save";
    // return this.http.post(url, payload);
  }

  getDiscountFactor(orgId) {
    const url = environment.apiVersion + `/org-config/get?configKey=ALTERNATIVE_DISCOUNT_FACTOR&orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/org-config/get?configKey=ALTERNATIVE_DISCOUNT_FACTOR&orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});
  }

  saveDiscountFactor(payload) {
    const url = environment.apiVersion + `/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/org-config/save";
    // return this.http.post(url, payload);
  }

  getGlobalAuditConfig(orgId) {
    const url = environment.apiVersion + `/org-config/get?configKey=AUDIT_TRAIL&orgId=${orgId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // const url = "http://localhost:8808/api/v1/org-config/get?configKey=AUDIT_TRAIL&orgId=" + orgId;
    // return this.http.get(url, {observe: "response"});
  }

  saveGlobalAuditConfig(payload) {
    const url = environment.apiVersion + `/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/org-config/save";
    // return this.http.post(url, payload);
  }

  getFundLevelStatus(fundId, quarter){
    let url = environment.apiVersion + `/saf/fund/status?fundId=${fundId}&quarter=${quarter}`;

    return this.appHttpRequest("GET", "saf", url, null).toPromise();

    // return this.http.get("http://localhost:8080/api" + url, {observe: "response"}).toPromise();
  }

  getBSLPInvestorData(companyId, valuationDateId, fundId, valuationDate){
    const url = environment.apiVersion + "/dashboard-report/inter-party-summary?companyId=" + companyId + "&valuationDateId=" + valuationDateId + "&fundId=" + fundId + "&valuationDate=" + valuationDate;
    return this.appHttpRequest("GET", "vcp", url, null).toPromise();

    // return this.http.get("http://localhost:8081/api" + url, {observe: "response"}).toPromise();
  }

  getFundsByOrgId(orgId){
    const url = environment.apiVersion + "/funds/getFundByOrgId?orgId=" + orgId;
    return this.appHttpRequest("GET", "vcp", url, null);

    // return this.http.get("http://localhost:8081/api/v1/funds/getFundByOrgId?orgId=" + orgId, {observe: "response"});
  }

  copyFundToOrg(oldUserId, newUserId, oldFundId, oldOrgId, newOrgId, adminUserId, fundName){
    const url = environment.apiVersion + "/funds/createDuplicateFund?oldUserId=" + oldUserId + "&newUserId=" + newUserId + "&oldFundId=" + oldFundId + "&oldOrgId=" + oldOrgId + "&newOrgId=" + newOrgId + "&adminUserId=" + adminUserId + "&fundName=" + fundName;
    return this.appHttpRequest("GET", "vcp", url, null);

    // return this.http.get("http://localhost:8081/api/v1/funds/createDuplicateFund?oldUserId=" + oldUserId + "&newUserId=" + newUserId + "&oldFundId=" + oldFundId + "&oldOrgId=" + oldOrgId + "&newOrgId=" + newOrgId + "&adminUserId=" + adminUserId + "&fundName=" + fundName, {observe: "response"});
  }

  getFundCloneStatus(){
    const url = environment.apiVersion + "/funds/getFundCloneStatus";
    return this.appHttpRequest("GET", "vcp", url, null);

    // return this.http.get("http://localhost:8081/api/v1/funds/getFundCloneStatus", {observe: "response"});
  }

  /**
   *
   * @param dateId : Object with units and array of debt profile ValuationDateId's
   * @returns Excel Blob data
   */
  public sendValuationDateId(dateId): Observable<Blob> {
    const h = this.getHeaderToken();
    let url = environment.apiGateway + environment.debtModelService + environment.apiVersion + '/downloads/excelReport';
    // let url = 'http://localhost:8808/api/v1/downloads/excelReport';
    return this.http.post(url, dateId, { responseType: 'blob', headers: h });
  }

  getIssuerCompnayReportData(body) {
    const url =  environment.apiVersion + "/debt-model/report/get-ppt-issuer-company-report-data";
    return this.appHttpRequest("POST", "debt", url, body).toPromise();

    // const body = {
    //   "fundId": "a0d2904a-23f4-4968-8d6b-38205eef3b74",
    //   "toCurrency": "USD",
    //   "units": "million",
    //   "companies": [
    //     {
    //       "companyId": "d76f434f-6a32-4f13-be16-6ad8088281f1",
    //       "issuerCompanyId": "55ecac4f-f9c4-477d-8a4e-f7c3571950bf",
    //       "valuationDates": [
    //         {
    //           "valuationDateId": "a0cbf2c1-a1a9-44fe-9348-a2198c855e08",
    //           "companyId": "d76f434f-6a32-4f13-be16-6ad8088281f1"
    //         }
    //       ]
    //     },
    //     {
    //       "companyId": "bde30b10-e0d6-4256-9e80-da3bd1b6524e",
    //       "issuerCompanyId": "2793a884-fbec-4548-9e1c-831ded08fc56",
    //       "valuationDates": [
    //         {
    //           "valuationDateId": "ff178c46-5df3-436d-8237-dc67ddce8e68",
    //           "companyId": "ff178c46-5df3-436d-8237-dc67ddce8e68"
    //         },
    //         {
    //           "valuationDateId": "ea6ae1f7-99ca-4154-ac0f-de78eff95db9",
    //           "companyId": "ea6ae1f7-99ca-4154-ac0f-de78eff95db9"
    //         },
    //         {
    //           "valuationDateId": "c83b3286-9877-4aee-bf55-60da27fdb1a0",
    //           "companyId": "bde30b10-e0d6-4256-9e80-da3bd1b6524e"
    //         }
    //       ]
    //     }
    //   ]
    // };
    // const url = "http://localhost:8808/api/v1/debt-model/report/get-ppt-issuer-company-report-data";
    // return this.http.post(url, body, { observe: 'response'}).toPromise();
  }

  convertImageToBase64(imageURL){
    // const url = "http://localhost:8081/api/v1/dashboard-report/url-to-base64-image?imageURL=" + imageURL;
    // return this.http.get(url, {observe: "response"}).toPromise();

    const url = environment.apiVersion + "/dashboard-report/url-to-base64-image?imageURL=" + imageURL;
    return this.appHttpRequest("GET", "vcp", url, null).toPromise();
  }

  getExitedInvestmentDetails(companyId, exitDate?: string) {
    let url;
    url = `${environment.apiVersion}/valuation/debt-model/exited-investment-details?companyId=${companyId}`;
    // url = `http://localhost:8808/api/v1/valuation/debt-model/exited-investment-details?companyId=${companyId}`;
    if(exitDate) {
      // url = `http://localhost:8808/api/v1/valuation/debt-model/exited-investment-details?companyId=${companyId}&exitDate=${exitDate}`;
      url = `${environment.apiVersion}/valuation/debt-model/exited-investment-details?companyId=${companyId}&exitDate=${exitDate}`;
    }
    // return this.http.get(url);
    return this.appHttpRequest("GET", "debt", url, null);
  }

  saveExitedInvestmentDetails(companyId, requestBody: { investmentStatus: string, exitDate?: string, percentOfPar?: string, notionalOutStanding?: string }) {
    const url = `${environment.apiVersion}/valuation/debt-model/save-exited-investment-details?companyId=${companyId}`;
    return this.appHttpRequest("POST", "debt", url, requestBody);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/save-exited-investment-details?companyId=${companyId}`;
    // return this.http.post(url, requestBody);
  }

  getWaterfallId(issuerCompanyId: string, valuationDate: string, concludeOnWaterfall: boolean = false) {
    let url = `${environment.apiVersion}/valuation/debt-model/lookUpWaterfall?issuerCompanyId=${issuerCompanyId}&valuationDate=${valuationDate}`;
    if(concludeOnWaterfall) {
      url = `${url}&concludedOnWaterfall=${concludeOnWaterfall}`
    }
    return this.appHttpRequest("GET", "debt", url, null).toPromise();

    // let url = `http://localhost:8808/api/v1/valuation/debt-model/lookUpWaterfall?issuerCompanyId=${issuerCompanyId}&valuationDate=${valuationDate}`;
    // if(concludeOnWaterfall) {
    //   url = `${url}&concludedOnWaterfall=${concludeOnWaterfall}`
    // }
    // return this.http.get(url).toPromise();
  }

  deleteSecurityByIds(waterfallId: string, securityIds: string[]) {
    const url = `${environment.apiVersion}/valuation/debt-model/deleteGoingConcernWaterfall?waterfallId=${waterfallId}`;
    return this.appHttpRequest("POST", "debt", url, securityIds);

    // const url = `http://localhost:8808/api/v1/valuation/debt-model/deleteGoingConcernWaterfall?waterfallId=${waterfallId}`;
    // return this.http.post(url, securityIds);
  }

  getWaterfallSecurities(issuerCompanyId: string, valuationDate: string) {
    let url = `${environment.apiVersion}/valuation/debt-model/getAllSecuritiesInWaterfall?issuerCompanyId=${issuerCompanyId}&valuationDate=${valuationDate}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // let url = `http://localhost:8808/api/v1/valuation/debt-model/getAllSecuritiesInWaterfall?issuerCompanyId=${issuerCompanyId}&valuationDate=${valuationDate}`;
    // return this.http.get(url);
  }

  updateWaterfallId(issuerCompanyId: string, valuationDate: string, concludeOnWaterfall: boolean = false) {
    let url = `${environment.apiVersion}/valuation/debt-model/updateConcludedOnWaterfall?issuerCompanyId=${issuerCompanyId}&valuationDate=${valuationDate}&concludedOnWaterfall=${concludeOnWaterfall}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // let url = `http://localhost:8808/api/v1/valuation/debt-model/updateConcludedOnWaterfall?issuerCompanyId=${issuerCompanyId}&valuationDate=${valuationDate}&concludedOnWaterfall=${concludeOnWaterfall}`;
    // return this.http.get(url);
  }

  getWaterfallValuationDates(issuerCompanyId: string) {
    let url = `${environment.apiVersion}/valuation/debt-model/waterfall_valuation_dates?issuerCompanyId=${issuerCompanyId}`;
    return this.appHttpRequest("GET", "debt", url, null).toPromise();

    // let url = `http://localhost:8808/api/v1/valuation/debt-model/waterfall_valuation_dates?issuerCompanyId=${issuerCompanyId}`;
    // return this.http.get(url).toPromise();
  }

  getTranslatedData(lang : string) {
    let url = '';
    switch (lang) {
      case 'EN':
        url = environment.translation_en_US
        break;
      case 'FR':
        url = environment.translation_fr_FR
        break;
      default:
        url = environment.translation_en_US
        break;
    }

    return this.http.get(url);
  }

  getResetValuesForWaterfallInputs(waterfallId: string) {
    let url = `${environment.apiVersion}/valuation/debt-model/resetGoingConcernInputs?waterfallId=${waterfallId}`;
    return this.appHttpRequest("GET", "debt", url, null);

    // let url = `http://localhost:8808/api/v1/valuation/debt-model/resetGoingConcernInputs?waterfallId=${waterfallId}`;
    // return this.http.get(url);
  }

  getDifferentDiscounting(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=DIFFERENT_DISCOUNTING&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);

    // const url = `http://localhost:8808/api/v1/org-config/get?configKey=DIFFERENT_DISCOUNTING&orgid=${orgId}`;
    // return this.http.get(url);
  }

  getNumberOfDecimals(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=NUMBER_OF_DECIMALS&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  saveNumberOfDecimals(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  saveDifferentDiscounting(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);

    // const url = "http://localhost:8808/api/v1/org-config/save";
    // return this.http.post(url, payload);
  }

  getWarrantValuationDiscount(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=WARRANT_VALUATION_DEBT_DISCOUNT_RATE&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  savewarrantValuationDiscount(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getOIDImpact(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=OID_IMPACT_ON_VALUATION&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  saveOIDImpact(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  saveCSAImpact(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getCSAImpact(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=CREDIT_SPREAD_ADJUSTMENT&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  getMiddleMarketAdj(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=MIDDLE_MARKET_ADJUSTMENT&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  getSpreadMatrixAdjustments(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=SPREAD_MATRIX_ADJUSTMENTS&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  getQualitativeAnalysisTitle(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=QUALITATIVE_ANALYSIS&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  saveMiddleMarketAdj(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  saveSpreadMatrixAdjustment(payload: { configKey: string, configValue: string, orgId: string }) {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest('POST', 'debt', url, payload);
  }

  getTags(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?orgId=${orgId}&configKey=BX_TAGS`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  saveTags(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  saveAdditionalInputs(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getAdditionalInput(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?orgId=${orgId}&configKey=ADDITIONAL_INPUTS`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  getAllDistinctValuationDates(fundId,onlyQuarterEndDates) {
    const url = `${environment.apiVersion}/debt/getAllDistinctValuationDatesInFund?fundId=${fundId}&onlyQuarterEndDates=${onlyQuarterEndDates}`;
    return this.appHttpRequest("GET", "saf", url, null);
  }

  saveCommonRollover(data) {
    const url = environment.apiVersion + '/valuation/debt-model/issuer-company-rollover';
    return this.appHttpRequest("POST", "debt", url, data);
  }

  saveComment(body) {
    const url = environment.apiVersion + '/comments/add';
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  deleteComment(id) {
    const url = environment.apiGateway + `/vcp/api/v1/comments/delete/${id}`;
    const h = this.getHeaderToken();
    return this.http.delete(url, {
      headers: h,
      observe: 'response'
    });
  }

  saveComment1(body) {
    const url = environment.apiVersion + '/comments/add';
    return this.appHttpRequest('POST', 'vcp', url, body);
  }
  updateAddToReportComment(body) {
    const url = environment.apiVersion + '/comments/addToReport';
    return this.appHttpRequest('POST', 'vcp', url, body);
  }

  getAllCommentsByFormId(formId) {
    const url = environment.apiVersion + '/comments/' + formId;
    return this.appHttpRequest('GET', 'vcp', url, null);
  }

  getAllComments(formId?) {
    const id = formId ? formId : this.formId;
    const url = environment.apiVersion + '/comments/' + id;
    return this.appHttpRequest('GET', 'vcp', url, null);
  }

  getThemeFromUam(orgId) {
    const url = environment.apiVersion + "/organizations/id?orgId=" + orgId;
    return this.appHttpRequest("GET", "uam", url, null);
  }

  insertConfigurationRecords(body) {

    const url = environment.apiVersion + '/configuration/insert-configuration-records';
    return this.appHttpRequest("POST", "vcp", url, body);
  }

  applyAllFromConfiguration(configKey, fundId) {
    const url = environment.apiVersion + '/configuration/apply-all/' + fundId + "/" + configKey;
    return this.appHttpRequest("POST", "vcp", url, null);

    // return this.http.get("http://localhost:8081/api" + url, {observe: "response"});
  }

  recordsByOrgId(orgId) {

    const url = environment.apiVersion + "/configuration/records-by-OrgId/" + orgId;

    return this.appHttpRequest("GET", "vcp", url, null);

  }

  recordsByCapturedForId(capturedForId) {

    const url = environment.apiVersion + "/configuration/records-by-Captured-for-Id/" + capturedForId;

    return this.appHttpRequest("GET", "vcp", url, null);

  }

  updateConfigurationRecords(id,value){
    const url = environment.apiVersion + "/configuration/update-value?id=" + id + "&value=" + value;
    return this.appHttpRequest("POST", "vcp", url, null);
  }

  getConfigurationDetails(fundId){
    const url = environment.apiVersion + "/configuration/records-by-Captured-for-Id/" + fundId;
    // return this.http.get("http://localhost:8807/api" + url, {headers: this.getHeaderToken(), observe: 'response' });
    return this.appHttpRequest('GET', 'vcp', url, null)
  }

  getThresholdData(formId){
    const url = environment.apiVersion + "/threshold/get-threshold-data/" + formId;
    // return this.http.get("http://localhost:8807/api" + url, {headers: this.getHeaderToken(), observe: 'response' });
    return this.appHttpRequest('GET', 'valuation', url, null)
  }

  refreshThresholdDataAndSave(formId, reqBody){
    const url = environment.apiVersion + "/threshold/refresh-threshold-data/" + formId;
    // return this.http.post("http://localhost:8807/api" + url, reqBody,  {headers: this.getHeaderToken(), observe: 'response' });
    return this.appHttpRequest('POST', 'valuation', url, reqBody)
  }

  saveTandCStatus() {
    const url = environment.apiVersion + '/users/consent/accept';
    return this.appHttpRequest('POST', 'uam', url, {});
  }

  getEnabledVersions(
    orgId: string
  ): Observable<HttpResponse<ApiResponse<EnabledVersion[]>>> {
    const url = `${environment.apiVersion}/dataVersion/getEnabledVersions?orgId=${orgId}`;
    // https://stringsuatapi.azure-api.net/monitoring/api/v1/dataVersion/getEnabledVersions?orgId=1144da52-7656-4a97-8f94-f074da127fc6
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });

    return this.dispatch('GET', 'monitoring', url, null);
  }

  /**
   *
   * @param uploadDate Date only field. Adjust to YYYY-MM-DD date and strip off time portion before passing.
   * @param fundId fundId
   * @returns Observable<HttpResponse<ApiResponse<VersionList[]>>>
   */
  getVersionLists(
    uploadDate: string,
    fundId: string,
  ): Observable<HttpResponse<ApiResponse<VersionList[]>>> {
    const url = `${environment.apiVersion}/saf/version-lists?uploadDate=${uploadDate}&fundId=${fundId}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('GET', 'saf', url, null);
  }

  getVersionListById(id: string): Observable<HttpResponse<ApiResponse<VersionListDetails>>> {
    const url = `${environment.apiVersion}/saf/version-lists/${id}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('GET', 'saf', url, null);
  }

  deleteVersionList(id) {
    const url = `${environment.apiVersion}/saf/version-lists/${id}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('DELETE', 'saf', url, null);
  }

  downloadVersionList(id) {
    const url = `${environment.apiGateway}${environment.safService}${environment.apiVersion}/saf/version-lists/${id}/download`;
    const token = this.getHeaderToken();
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
   return this.http.get(url, {
      responseType: 'blob',
      headers: token,
    });
  }

  isVlNameUnique(vlName: VlName): Observable<HttpResponse<ApiResponse<{ isExists: boolean }>>> {
    const url = `${environment.apiVersion}/saf/version-lists/name-exists`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, vlName);
  }

  saveVersionList(
    newVersionList: NewVersionList
  ): Observable<HttpResponse<ApiResponse<{ id: string }>>> {
    const url = `${environment.apiVersion}/saf/version-lists`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, newVersionList);
  }

  updateVersionList(
    newVersionList: NewVersionList,id: string
  ): Observable<HttpResponse<ApiResponse<{ id: string }>>> {
    const url = `${environment.apiVersion}/saf/version-lists/${id}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('PUT', 'saf', url, newVersionList);
  }

  /**
   *
   * @param fundId fundId
   * @description API to get all companies in fund along with valuation details for earlies and latest valuation dates
   * @returns Observable<HttpResponse<ApiResponse<CompaniesInFundMap[]>>>
   */
    getAllCompaniesInFundWithEarliestAndLatestValuationDetails(fundId:string): Observable<HttpResponse<ApiResponse<CompaniesInFundMap>>> {
      const url = `${environment.apiVersion}/saf/latest-valuation-dates-of-companies-by-fund-and-upper-limit/${fundId}?detailsNeeded=${true}`;
      // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });

      return this.dispatch('GET', 'saf', url, null);
    }

  /**
   *
   * @param fundId fundId
   * @param uploadDate Date only field. Adjust to YYYY-MM-DD date and strip off time portion before passing.
   * @returns Observable<HttpResponse<ApiResponse<AssignedCompany[]>>>
   */
  getCompanyAssociations(fundId:string, uploadDate: string): Observable<HttpResponse<ApiResponse<AssignedCompany[]>>> {
    const url = `${environment.apiVersion}/saf/company-assignments?fundId=${fundId}&uploadDate=${uploadDate}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });

    return this.dispatch('GET', 'saf', url, null);
  }

  associateVlToCompanies(reqBody: CompanyToVersionListAndAttributeList): Observable<HttpResponse<ApiResponse<{ ids: number[] }>>> {
    const url = `${environment.apiVersion}/saf/company-assignments`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, reqBody);
  }

  validateCompanyAssociations(reqBody: Pick<CompanyToVersionListAndAttributeList, 'companyIds' | 'uploadDate' | 'fundId'>): Observable<HttpResponse<ApiResponse<{ companyIds: string[] }>>> {
    const url = `${environment.apiVersion}/saf/company-assignments/exists`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, reqBody);
  }

  deleteCompanyAssociation(id: string): Observable<HttpResponse<ApiResponse<{}>>> {
    const url = `${environment.apiVersion}/saf/company-assignments/${id}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('DELETE', 'saf', url, null);
  }

  getGxAttributes(orgId: string) {
    const url = `${environment.apiVersion}/attributeV2/getAllOrgTemplateAttributes?orgId=${orgId}`;
    // https://stringsuatapi.azure-api.net/monitoring/api/v1/dataVersion/getEnabledVersions?orgId=1144da52-7656-4a97-8f94-f074da127fc6
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });

    return this.dispatch('GET', 'monitoring', url, null);
  }

  saveShadowRating(payload: { orgId: string, orgSettingsKey: any, isActive: boolean }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/settings`;
    return this.appHttpRequest("PUT", "debt", url, payload);
  }

  saveConfigStatus(payload: { orgId: string, orgSettingsKey: any, isActive: boolean }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/settings`;
    return this.appHttpRequest("PUT", "debt", url, payload);
  }

  saveQualitativeAnalysis(payload:any){
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getGlobalConfig(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/settings?orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  getShadowRating(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/settings?orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }
  postequityOwnershipInCompany(issuerCompanyId, reqBody) {
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/equityOwnershipInCompany';
    return this.appHttpRequest('POST', 'debt', url, reqBody);
  }
  getequityOwnershipInCompany(issuerCompanyId) {
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/equityOwnershipInCompany';
    return this.appHttpRequest('GET', 'debt', url, {});
  }
  getEquityConsolidatedSummary(issuerCompanyId) {
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/equityConsolidatedSummary';
    return this.appHttpRequest('GET', 'debt', url, {});
  }

  multipleBusinessUnit(consolFormId : string) : Observable<any> {
    const url = `${environment.apiVersion}/saf/get-business-units-forms-from-consol?consolFormId=${consolFormId}`;
    return this.appHttpRequest('GET', 'saf', url, null);
  }

  getCapitalStructureSummmary(issuerCompanyId){
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/capital_structure';
    return this.appHttpRequest('GET', 'debt', url, {});
  }
  putCapitalStructureSummmary(issuerCompanyId,payload){
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/capital_structure';
    return this.appHttpRequest('PUT', 'debt', url, payload);
  }
  getTransctionOverviewSummmary(issuerCompanyId, valuationDate) {
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/capital_structure/transactions_view?valuationDate=' + valuationDate;
    return this.appHttpRequest('GET', 'debt', url, {});
  }
  saveTransctionOverviewSummmary(issuerCompanyId, payload) {
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/capital_structure/transactions_view';
    return this.appHttpRequest('PUT', 'debt', url, payload);
  }

  getAttributesLists(
    uploadDate: string,
    fundId: string,
  ): Observable<HttpResponse<ApiResponse<AttributeList[]>>> {
    const url = `${environment.apiVersion}/saf/gx-qx-linkage/attribute-lists?uploadDate=${uploadDate}&fundId=${fundId}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('GET', 'saf', url, null);
  }

  getAttributeListDetailsById(
    attributeListId:string
  ): Observable<HttpResponse<ApiResponse<AttributeListDetails>>> {
    const url = `${environment.apiVersion}/saf/gx-qx-linkage/attribute-lists/${attributeListId}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('GET', 'saf', url, null);
  }

  saveAttributeMap(
    newAttributeMap: NewAttributesMap
  ): Observable<HttpResponse<ApiResponse<{ id: string }>>> {
    const url = `${environment.apiVersion}/saf/gx-qx-linkage/attribute-lists`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, newAttributeMap);
  }

  isAttributeListNameUnique(attributeListName: AttributeListName): Observable<HttpResponse<ApiResponse<{ isExists: boolean }>>> {
    const url = `${environment.apiVersion}/saf/gx-qx-linkage/attribute-lists/name-exists`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, attributeListName);
  }

  deleteAttributeList(attributeListId: string): Observable<HttpResponse<ApiResponse<{}>>> {
    const url = `${environment.apiVersion}/saf/gx-qx-linkage/attribute-lists/${attributeListId}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('DELETE', 'saf', url, null);
  }

  downloadAttributeList(attributeListId: string) {
    const url = `${environment.apiGateway}${environment.safService}${environment.apiVersion}/saf/gx-qx-linkage/attribute-lists/${attributeListId}/download`;
    const token = this.getHeaderToken();
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
   return this.http.get(url, {
      responseType: 'blob',
      headers: token,
    });
  }

  getDiscountRateFinacialMatrix(companyId){
    const url = `${environment.apiVersion}/valuation/debt-model/getMetricTables?companyId=${companyId}`;
    return this.appHttpRequest('GET', 'debt', url, {});
  }

  getSpreadBuildUp(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=TRANCHE_ANALYSIS&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }
  saveSpreadBuildUp(payload: { configKey: string, configValue: any, orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  getCompoundingInterestPayments(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=COMPOUNDING_INTEREST_PAYMENTS_SUPPORTED&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  getValuationSignOff(orgId: string): Observable<any> {
    const url = `${environment.apiVersion}/org-config/get?configKey=FUND_LEVEL_VALUATION_SIGN_OFF&orgId=${orgId}`;
    return this.appHttpRequest('GET', 'debt', url, null);
  }

  saveCompoundingInterestPayments(payload: { configKey: string; configValue: any; orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest('POST', 'debt', url, payload);
  }

  saveGenericConfig(payload: { configKey: string; configValue: any; orgId: string }): Observable<any> {
    const url = `${environment.apiVersion}/org-config/save`;
    return this.appHttpRequest('POST', 'debt', url, payload);
  }

  getValuationDates(issuerCompanyId: string): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/dates`;
    return this.appHttpRequest("GET", "debt", url, null)
  }
  getInputsData(issuerCompanyId: string, valDate): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/inputs?date=${valDate}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }
  saveInputs(issuerCompanyId, valuationDates, payload): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/inputs?date=${valuationDates}`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }
  getEvData(issuerCompanyId: string, valDate): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/ev?date=${valDate}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }
  saveEvData(issuerCompanyId, valuationDates, payload): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/ev?date=${valuationDates}`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }

  resetEvData(issuerCompanyId: string, valuationDate: string) {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/ev/reset?date=${valuationDate}`;
    return this.dispatch('POST', 'debt', url, null);
  }

  getAllSecuritiesForTrancheAnalysis(issuerCompanyId): Observable<HttpResponse<ApiResponse<ITrancheSecurity[]>>> {
    const url = environment.apiVersion + `/valuations/tranches/${issuerCompanyId}/securities`;
    return this.dispatch("GET", "debt", url, null);
  }
  addTranche(issuerCompanyId, valuationDates, payload): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}?date=${valuationDates}`;
    return this.appHttpRequest("POST", "debt", url, payload);
  }
  updateTranche(issuerCompanyId: string, selectedValuationDate: string, payload: any): Observable<any>{
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/edit?date=${selectedValuationDate}`;
    return this.dispatch("POST", "debt", url, payload);
  }
  getCompanyCapitalization(issuerCompanyId, selectedValuationDate): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/companyCapitalization?date=${selectedValuationDate}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }
  getMarketCapitalization(issuerCompanyId, selectedValuationDate): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/marketCapitalization?date=${selectedValuationDate}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }
  getMarketReTranching(issuerCompanyId, selectedValuationDate): Observable<any> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/marketReTranche?date=${selectedValuationDate}`;
    return this.appHttpRequest("GET", "debt", url, null);
  }
  saveConcludeTrancheValuation(issuerCompanyId, valuationDates, event: { conclude: boolean }) {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/conclude?date=${valuationDates}`;
    return this.dispatch("POST", "debt", url, event) as Observable<HttpResponse<ApiResponse<ITrancheConclude>>>;
  }

  getTrancheValuationStatus(
    issuerCompanyId: string, valuationDate: string
  ): Observable<HttpResponse<ApiResponse<ITrancheStatus>>> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/status?date=${valuationDate}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  getAllSecuritiesAllocatedToTranche(
    issuerCompanyId: string, valuationDate: string, trancheType: 'REVOLVER' | 'UNI_TRANCHE'
  ): Observable<HttpResponse<ApiResponse<ITrancheSecurity[]>>> {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/securities/allocations?type=${
      trancheType
    }&date=${valuationDate}`;
    return this.dispatch('GET', 'debt', url, null);
  }

  allocateSecuritiesToTranche(
    issuerCompanyId: string, valuationDate: string,
    payload: { trancheType: 'REVOLVER' | 'UNI_TRANCHE', securities: ITrancheSecurity[] }
  ) {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/securities/allocations?date=${valuationDate}`;
    return this.dispatch('POST', 'debt', url, payload);
  }

  getTrancheConclude(issuerCompanyId: string, valuationDate: string) {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/conclude?date=${valuationDate}`;
    return this.dispatch('GET', 'debt', url, null) as Observable<HttpResponse<ApiResponse<ITrancheConclude>>>;
  }

  evaluateTrancheValuation(issuerCompanyId: string, valuationDate: string) {
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/evaluate?date=${valuationDate}`;
    return this.dispatch('PUT', 'debt', url, null);
  }

  createVehicle(reqBody){
    const url = environment.apiVersion + "/mandates/save";
    return this.appHttpRequest("POST", 'vcp', url, reqBody);

    // return this.http.post("http://localhost:8081/api" + url, reqBody, { observe: "response" });
  }

  getVehiclesByFundId(fundId) {
    const url = environment.apiVersion + "/mandates/get-all-by-parent/" + fundId;
    return this.appHttpRequest('GET', 'vcp', url, null);

    // return this.http.get("http://localhost:8081/api" + url, { observe: "response" });
  }

  deleteRecord(issuerCompanyId:any,payload:any){
    const url = environment.apiVersion + '/issuerCompany/' + issuerCompanyId + '/capital_structure';
    return this.appHttpRequest('POST', 'debt', url, payload)
  }

  postInvestmentSpecificAdjustment(issuerCompanyId: string, valuationDate: string,payload:any){
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/investmentSpecificAdjustment?date=${valuationDate}`;
    return this.appHttpRequest('POST', 'debt', url, payload)
  }

  getInvestmentSpecificAdjustment(issuerCompanyId: string, valuationDate: string,){
    const url = `${environment.apiVersion}/valuations/tranches/${issuerCompanyId}/investmentSpecificAdjustment?date=${valuationDate}`;
    return this.appHttpRequest('GET', 'debt', url, null)
  }

  getShadowRatingMatricTable(companyId){
    const url = `${environment.apiVersion}/customRating/company/${companyId}`;
    return this.appHttpRequest('GET', 'debt', url, {});
  }

  getSecurityLinkage(securityId: any[]): Observable<any> {
    // curl --location --globoff '{{CT_API_SERVER}}/api/v1/securityLinkage/securityDetails?securityId=03ff5ced-3d93-42d8-9491-bcfbba311b6e'
    const url = `${environment.apiVersion}/securityLinkage/securityDetails`;
    return this.appHttpRequest('POST', 'debt', url, securityId);
  }

  deleteAllCompanies(securityIds: any[]): Observable<any> {
    const url = `${environment.apiVersion}/securityLinkage/securityDetailsForDeletion`;
    return this.appHttpRequest('POST', 'debt', url, securityIds);
  }

  getAllDebtCompaniesInFund(fundId:string): Observable<HttpResponse<ApiResponse<DebtAssignedCompany[]>>> {
    const url = `${environment.apiVersion}/valuation/debt-model/getAllCompaniesInFund?fundId=${fundId}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });

    return this.dispatch('GET', 'debt', url, null);
  }

  /**
   *
   * @param fundId fundId
   * @param uploadDate Date only field. Adjust to YYYY-MM-DD date and strip off time portion before passing.
   * @returns Observable<HttpResponse<ApiResponse<AssignedCompany[]>>>
   */
  getDebtCompanyAssociations(fundId:string, uploadDate: string): Observable<HttpResponse<ApiResponse<AssignedCompany[]>>> {
    const url = `${environment.apiVersion}/saf/gx-credit-linkage/debt-assignments?fundId=${fundId}&uploadDate=${uploadDate}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });

    return this.dispatch('GET', 'saf', url, null);
  }

  associateVlAndAttrsToDebtCompanies(reqBody: CompanyToVersionListAndAttributeList): Observable<HttpResponse<ApiResponse<{ ids: number[] }>>> {
    const url = `${environment.apiVersion}/saf/gx-credit-linkage/debt-assignments`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, reqBody);
  }

  validateDebtCompanyAssociations(reqBody: Pick<CompanyToVersionListAndAttributeList, 'companyIds' | 'uploadDate' | 'fundId'>): Observable<HttpResponse<ApiResponse<{ companyIds: string[] }>>> {
    const url = `${environment.apiVersion}/saf/gx-credit-linkage/debt-assignments/exists`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('POST', 'saf', url, reqBody);
  }

  deleteDebtCompanyAssociation(id: string): Observable<HttpResponse<ApiResponse<{}>>> {
    const url = `${environment.apiVersion}/saf/gx-credit-linkage/debt-assignments/${id}`;
    // return this.http.get("http://localhost:8807/api" + url, { headers: this.getHeaderToken(), observe: "response" });
    return this.dispatch('DELETE', 'saf', url, null);
  }
  getAll73StringsClients() {
    const url = `${environment.apiVersion}/health/check/getOrgs`;
    return this.appHttpRequest('POST', 'reporting-v2', url, {});
  }

  getFinancialsAndGeneralDetailsDiffInGxAndExcelReports(orgIds: string[]) {
    const url = `${environment.apiVersion}/health/check/financialsGeneralAndProfileDetailsByOrgId`;
    // return of ({
    //   "response": [
    //     {
    //         "orgId": "38c25ca4-fc7d-42e4-9d35-30cb6e7f18ba",
    //         "orgName": "API Testing",
    //         "reporting": [
    //             {
    //                 "companyId": "465596e0-5a4c-411b-990c-8909efee5321",
    //                 "companyName": "Infosys Limited",
    //                 "financialRows": 3.0,
    //                 "generalDetailsRows": 3.0,
    //                 "profileDetailsRows": 0.0
    //             },
    //             {
    //               "companyId": "465596e0-5a4c-411b-990c-8909efee55656",
    //               "companyName": "Sec 31",
    //               "financialRows": 3.0,
    //               "generalDetailsRows": 3.0,
    //               "profileDetailsRows": 0.0
    //           }
    //         ],
    //         "monitoring": [
    //             {
    //                 "companyId": "465596e0-5a4c-411b-990c-8909efee5285",
    //                 "companyName": "LTI",
    //                 "financialRows": 3.0,
    //                 "generalDetailsRows": 3.0,
    //                 "profileDetailsRows": 4.0
    //             },
    //             {
    //                 "companyId": "465596e0-5a4c-411b-990c-8909efee5321",
    //                 "companyName": "Infosys Limited",
    //                 "financialRows": 3.0,
    //                 "generalDetailsRows": 3.0,
    //                 "profileDetailsRows": 6.0
    //             }
    //         ]
    //     },
    //     {
    //       "orgId": "38c25ca4-fc7d-42e4-9d35-30cb6e7f1432",
    //       "orgName": "Test org",
    //       "reporting": [
    //           {
    //               "companyId": "465596e0-5a4c-411b-990c-8909efee5245",
    //               "companyName": "Infosys Limited",
    //               "financialRows": 3.0,
    //               "generalDetailsRows": 3.0,
    //               "profileDetailsRows": 0.0
    //           }
    //       ],
    //       "monitoring": [
    //           {
    //               "companyId": "346ji8789-2345-411b-990c-8909efee5895",
    //               "companyName": "LTI",
    //               "financialRows": 3.0,
    //               "generalDetailsRows": 3.0,
    //               "profileDetailsRows": 4.0
    //           },
    //           {
    //               "companyId": "465596e0-5a4c-411b-990c-8909efee5245",
    //               "companyName": "Infosys Limited",
    //               "financialRows": 3.0,
    //               "generalDetailsRows": 3.0,
    //               "profileDetailsRows": 6.0
    //           }
    //       ]
    //   },
    //   {
    //     "orgId": "38c25ca4-fc7d-42e4-9d35-30cb6e7f1999",
    //     "orgName": "Test org II",
    //     "reporting": [
    //         {
    //             "companyId": "125596e0-5a4c-411b-990c-8909efee5245",
    //             "companyName": "ABC",
    //             "financialRows": 3.0,
    //             "generalDetailsRows": 3.0,
    //             "profileDetailsRows": 0.0
    //         }
    //     ],
    //     "monitoring": [
    //         {
    //             "companyId": "126ji8789-2345-411b-990c-8909efee5895",
    //             "companyName": "PQR",
    //             "financialRows": 3.0,
    //             "generalDetailsRows": 3.0,
    //             "profileDetailsRows": 4.0
    //         },
    //         {
    //             "companyId": "125596e0-5a4c-411b-990c-8909efee5245",
    //             "companyName": "ABC",
    //             "financialRows": 3.0,
    //             "generalDetailsRows": 3.0,
    //             "profileDetailsRows": 6.0
    //         }
    //     ]
    // },
    // ],
    // "success": true,
    // "message": "Success",
    // "errorCode": null
    // });
    return this.appHttpRequest('POST', 'reporting-v2', url, { orgIds });
  }

  getSnowflakeFinancialsAndGeneralDetailsDiffInGxAndExcelReports(orgIds: string[]) {
    const url = `${environment.apiVersion}/health/check/financialsGeneralAndProfileDetailsByOrgId`;
    return this.appHttpRequest('POST', 'reporting-v2', url, { orgIds, dbType: 'snowflake'});
  }

  getAttributeDetailsDiffInGxAndExcelReports(orgIds: string[]) {
    const url = `${environment.apiVersion}/health/check/attributeDetailsByOrgId`;
    return this.appHttpRequest('POST', 'reporting-v2', url, { orgIds });
  }

  getUserCountByOrgId(orgIds: string[]) {
    const url = `${environment.apiVersion}/health/check/userDetailsByOrgId`;

    // return of({
    //   "response": [
    //     {
    //         "reporting": [
    //             {
    //                 "orgId": "38c25ca4-fc7d-42e4-9d35-30cb6e7f18ba",
    //                 "orgName": "API Testing",
    //                 "userCount": 26
    //             }
    //         ],
    //         "monitoring": [
    //             {
    //                 "orgId": "38c25ca4-fc7d-42e4-9d35-30cb6e7f18ba",
    //                 "orgName": 'API Testing',
    //                 "userCount": 1
    //             }
    //         ]
    //     }
    // ],
    // "success": true,
    // "message": "Success",
    // "errorCode": null

    // });
    return this.appHttpRequest('POST', 'reporting-v2', url, { orgIds });
  }

  getUserCompanyDetailsByOrgId(orgIds: string[]) {
    const url = `${environment.apiVersion}/health/check/userCompanyDetailsByOrgId`;

    // return of({
    //   "response": [
    //     {
    //         "orgId": "38c25ca4-fc7d-42e4-9d35-30cb6e7f18ba",
    //         "orgName": "API Testing",
    //         "reporting": [
    //             {
    //                 "userUuid": "009c08a2-c051-4795-a925-af743a37c188",
    //                 "userId": "admin@73apitesting.com",
    //                 "userName": "Admin",
    //                 "companyCount": 812
    //             }
    //         ],
    //         "monitoring": [
    //             {
    //                 "userUuid": "009c08a2-c051-4795-a925-af743a37c188",
    //                 "userId": "admin@73apitesting.com",
    //                 "userName": "Admin",
    //                 "companyCount": 27
    //             }
    //         ]
    //     }]
    // });
    return this.appHttpRequest('POST', 'reporting-v2', url, { orgIds });
  }

  getGxAndReportingSyncStatus(orgIds): Observable<HttpResponse<any>> {
    const url = `${environment.apiVersion}/health/check/getProcessingStatus`;
    return this.appHttpRequest('POST', 'reporting-v2', url, { orgIds });
  }

  getProductAccessByUserId(userId: string): Promise<HttpResponse<ApiResponse<S73Product[]>>> {
    const url = `/v2/users/products?userId=${userId}`;

    return this.dispatch('GET', 'uam', url, null).toPromise() as Promise<HttpResponse<ApiResponse<S73Product[]>>>;
  } 

  // Pluto Data Recon APIs
  getGxDataLoadStatus(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/monitoring/reconDashboard/getLastUpdatedTimestamp`;
    return this.appHttpRequest('POST', 'internal-visualization-tool', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getGxFinancialsGDProfileDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/monitoring/reconDashboard/getFinancialGdProfileCountAndLastUpdated`;
    return this.appHttpRequest('POST', 'internal-visualization-tool', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getGxAttributeDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/monitoring/reconDashboard/getAttributeCountAndLastUpdated`;
    return this.appHttpRequest('POST', 'internal-visualization-tool', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getGxUserCompanyAccessDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/monitoring/reconDashboard/getOrgUserCompanyCount`;
    return this.appHttpRequest('POST', 'internal-visualization-tool', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getGxOrganizationUserDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/monitoring/reconDashboard/getOrgUserCount`;
    return this.appHttpRequest('POST', 'internal-visualization-tool', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getPlutoDataReconClients(): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/recon/getOrgList`;
    return this.appHttpRequest('POST', 'pluto-service', url, {}) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getPlutoDataLoadStatus(): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/etl/getDataLoadStatus`;
    return this.appHttpRequest('POST', 'pluto-service', url, {}) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getPlutoFinancialsGDProfileDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/recon/financialGdProfileDataCount`;
    return this.appHttpRequest('POST', 'pluto-service', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getPlutoAttributeDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/recon/attributeAndFormulaCompanyDataCount`;
    return this.appHttpRequest('POST', 'pluto-service', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getPlutoUserCompanyAccessDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/recon/orgUserCompaniesAccessDataCount`;
    return this.appHttpRequest('POST', 'pluto-service', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  getPlutoOrganizationUserDetails(orgIds: string[]): Observable<HttpResponse<ApiResponse<any>>> {
    const url = `${environment.apiVersion}/recon/orgUserDataCount`;
    return this.appHttpRequest('POST', 'pluto-service', url, { orgIds }) as Observable<HttpResponse<ApiResponse<any>>>;
  }

  savePowerBiReportConfigs(orgId,body) {
    const url = `${environment.apiVersion}/powerBIDashboard/savePowerBiDashboards?orgId=${orgId}`;
    return this.appHttpRequest('POST', 'monitoring', url, body);
    // const url = "http://localhost:8809/api/v1/powerBIDashboard/savePowerBiDashboards?orgId="+orgId;
    // return this.http.post(url, body, { observe: 'response'});
  }

  getConfigs(orgId) {
    const url = `${environment.apiVersion}/powerBIDashboard/getPowerBiDashboards?orgId=${orgId}`;
    return this.appHttpRequest('GET', 'monitoring', url, null);
    // const url = "http://localhost:8809/api/v1/powerBIDashboard/getPowerBiDashboards?orgId="+orgId;
    // return this.http.get(url, { observe: 'response'});
  }

}
