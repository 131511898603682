declare var APP_ENV: any;

export const environment = {
  environment:APP_ENV.APP_ENVIRONMENT,
  production: APP_ENV.APP_PRODUCTION,
  
  apiVersion: "/v1",
  apiSAFAnswers: "/saf/answer",
  apiSAFNewForm: "/saf/form",

  apiGateway: APP_ENV.APP_API_GATEWAY,   //"https://vcpdevapims.azure-api.net", //"http://localhost:9090"
  vcpService: "/vcp/api", // VC Platform services
  safService: "/saf/api", // SAF services
  umService: "/user/api", // User Management services
  uamService:"/uam/api",  // User Management services 2.0
  valuationServiceSuffix: "/valuation/api",
  thirdPartyService: '/thirdpartyapi/api',
  exchangeRateApi: '/exchangeRate/singleExchangeRate',
  valDateExchangeRateAPI: '/valuation/currency-exchange',
  debtModelService: "/debtmodel/api",
  approvalSystemService: "/approval/api",

  twitterService: "https://ubuntuvm.centralus.cloudapp.azure.com/twitterapi/",
  
  portalUrl: APP_ENV.APP_PORTAL_URL,
  generalForms: "/forms",
  ivForms: "/iv-forms",
  corpForms: "/corp-forms",
  pvValuation: "/pv", //Portfolio Valuation
  impValuation: "/impairment", //Impairment Valuation
  projects: "/projects",
  safUrl: "/applicationform",
  imagePath: './',
  debtModel: '/debt-model/',
  simulation: '/simulation',

  document360: 'https://73-strings-kb.us.document360.io/jwt/authorize?code=',
  portcoSignIn: 'https://73stringsuat.b2clogin.com/73stringsuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN_PASSWORDLESS_ONLY&&client_id=30ce0ed9-f04b-4c8f-b5d3-8a40e235a208&nonce=defaultNonce&redirect_uri=https%3A%2F%2Faiplatform-uat.73strings.com&scope=openid&response_type=id_token&prompt=login',

  grapeCityLicense: "*.73strings.com,987764748876692#B1v7uUcUNkcvoWTYBzUpVTZkFDVNpVTEVjYpFnSk5GNmFHemNDeVd7bYBlZzQ7ZjhlRidGdzYlWtdVRyoUOydmc4V5TSRDR824L6N5QHRTV9QkTlhUZMl7bON6dy84UKVUS9J6RzYHRClkZJdDZYF4Q4RDcTpERuVFZh5kdkFnQ9hkVERzVQRlet5mbqhkSHlVMYh6T0NDSxgGTrcVeChmYjBVTjRnaPl7aaplSRZlcKZHU4NXauhjY5JnV844Z0R6dr24MvVHb8JVT9RlNThGV7UlYrJXTnhXNR3mN6IEexU6KMFWeQRmSPVUevglNRJ6QiojITJCLiMjMCZEN4EkNiojIIJCL7QjMzQzMwUTM0IicfJye&Qf35VficFVDplI0IyQiwiI6EjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICNzQDMyADI5ADMxMjMwIjI0ICdyNkIsISbvNmLzdmbpJHdzNzNuoiI0IyctRkIsIyUBNFIzdmbpJHdTByM7IiOiEmTDJCLiITO6YzN8gDN7QjN7cDO9IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7ZnUyYHbQZ6U7U5RGhmby54REd7a9F4b8YEZzEWSDJEUNF5Rzo7TaJFZvsmduBHZvVlQmRVdvF6b7hDOvckeMNEWPpEOGxEUDxmdFR7UapkVmRza8M5dsBDV7cEeOpFVwU4nml2",
  apiDownload: '/files/download',
  translation_en_US: "https://73suatstorage.blob.core.windows.net/trading/en_US.json",
  translation_fr_FR: "https://73suatstorage.blob.core.windows.net/trading/fr_FR.json",
  currency_dashboard_template: "https://73backup.blob.core.windows.net/platform-files/Client Currency Rates Template.xlsx",
  // Add copied session parameters here. To copy session parameters, go to hosted platform URL, and press ctrl+shift+K or ctrl+alt+c
  // Paste the copied text like below after "dev" key
  
  // dev: {}
};